import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ContactUs',{ref:"OpenContactForm"}),_c(VAppBar,{staticStyle:{"height":"6em"},attrs:{"app":"","color":"light-blue lighten-5"}},[_c('a',{attrs:{"href":"/"}},[(_vm.$vuetify.breakpoint.mdAndDown)?_c(VImg,{staticClass:"mt-7",attrs:{"src":"img/newLogo.jpg","max-height":"150","max-width":"150","contain":""}}):_c(VImg,{staticClass:"mt-7",attrs:{"src":"img/newLogo.jpg","max-height":"130","max-width":"130","contain":""}})],1),_c(VSpacer),_c('div',{staticClass:"ml-5 mt-10"},[_c('LanguageSelection')],1),_c(VBtn,{staticClass:"mt-10",attrs:{"icon":""},on:{"click":function($event){return _vm.toggleNavbar()}}},[_c(VIcon,[_vm._v("mdi-menu")])],1),_c(VBtn,{staticClass:"mt-10",attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.OpenContactForm.openContactDialog()}}},[_c(VIcon,[_vm._v("mdi-email-outline")])],1)],1),_c(VNavigationDrawer,{attrs:{"app":""},model:{value:(_vm.navDrawer),callback:function ($$v) {_vm.navDrawer=$$v},expression:"navDrawer"}},[_c('a',{attrs:{"href":"/"}},[(_vm.$vuetify.breakpoint.mdAndDown)?_c(VImg,{staticClass:"mt-5",attrs:{"src":"img/newLogo.jpg","max-height":"150","max-width":"150","contain":""}}):_c(VImg,{staticClass:"mt-5",attrs:{"src":"img/newLogo.jpg","max-height":"130","max-width":"130","contain":""}})],1),_c(VDivider),_c(VList,{attrs:{"dense":""}},[_vm._l((_vm.pageNavigation),function(item,index){return _c(VListItem,{key:index,attrs:{"to":item.location},on:{"click":function($event){$event.preventDefault();return _vm.registerClickedElement(item)}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.name))])],1)],1)}),_c(VListItem,{on:{"click":function($event){return _vm.$refs.OpenContactForm.openContactDialog()}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-email-outline")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t("navbar.contact")))])],1)],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }