<template>
  <div class="homePageWidth">
    <v-row>
      <!-- ===== About Company ===== -->
      <v-col
        cols="12"
        xl="2"
        lg="2"
        md="2"
        sm="12"
        xs="12"
        class="pt-0"
      ></v-col>
      <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12" class="mt-15">
        <v-row>
          <v-col cols="12">
            <p class="aboutTitle">{{ $t("aboutpage.aboutTitle") }}</p>
            <p class="aboutDescription">
              {{ $t("aboutpage.aboutTextOne") }} <br />
              {{ $t("aboutpage.aboutTextTwo") }} <br />
              {{ $t("aboutpage.aboutTextThree") }} <br />
              {{ $t("aboutpage.aboutTextFour") }}<br />
              {{ $t("aboutpage.aboutTextFive") }}
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12"></v-col>

      <!-- ===== Aims and Results ===== -->
      <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12"></v-col>
      <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12" class="mt-15">
        <v-row>
          <v-col cols="12" xl="5" lg="5" md="5" sm="12" xs="12">
            <v-row>
              <!-- Main Aim -->
              <v-col cols="12">
                <p class="goalTitle">{{ $t("aboutpage.mainAimsTitle") }}</p>
                <p class="goalDescription">
                  {{ $t("aboutpage.mainAimsTextOne") }} <br />
                  {{ $t("aboutpage.mainAimsTextTwo") }} <br />
                  {{ $t("aboutpage.mainAimsTextThree") }}
                </p>
              </v-col>
              <!-- Aims -->
              <v-col cols="12" class="mb-15">
                <p class="goalTitle">{{ $t("aboutpage.aimsTitle") }}</p>
                <p class="goalDescription">
                  <strong>{{ $t("aboutpage.aimsTextOne") }}</strong
                  ><br />
                  {{ $t("aboutpage.aimsTextTwo") }}
                  <br />
                  {{ $t("aboutpage.aimsTextThree") }} <br />
                  {{ $t("aboutpage.aimsTextFour") }}
                  <br />
                  {{ $t("aboutpage.aimsTextFive") }} <br />
                  {{ $t("aboutpage.aimsTextSix") }}
                </p>
              </v-col>
            </v-row>
          </v-col>
          <!-- ===== Results ===== -->
          <v-col cols="12" xl="7" lg="7" md="7" sm="12" xs="12">
            <p class="goalTitle">{{ $t("aboutpage.resultsTitle") }}</p>
            <v-expansion-panels>
              <v-expansion-panel v-for="(item, index) in results" :key="index">
                <v-expansion-panel-header>
                  <p class="goalDescription" style="font-weight: bold">
                    {{ item.title }}
                  </p>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p class="ma-0 pa-0 goalDescription">
                    {{ item.descriptionOne }}
                  </p>
                  <p class="ma-0 pa-0 goalDescription">
                    {{ item.descriptionTwo }}
                  </p>
                  <p class="ma-0 pa-0 goalDescription">
                    {{ item.descriptionThree }}
                  </p>
                  <p class="ma-0 pa-0 goalDescription">
                    {{ item.descriptionFour }}
                  </p>
                  <p class="ma-0 pa-0 goalDescription">
                    {{ item.descriptionFive }}
                  </p>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "About Page",
  components: {},
  data() {
    return {
      keyBoxes: [
        { title: "Some key words" },
        { title: "To describe the project" },
        { title: "To make it more digestible" },
        { title: "To describe the project" },
        { title: "Some key words" },
      ],
      results: [
        {
          title: this.$t("aboutpage.resultsOneTitle"),
          descriptionOne: this.$t("aboutpage.resultsOneTextOne"),
          descriptionTwo: this.$t("aboutpage.resultsOneTextTwo"),
          descriptionThree: this.$t("aboutpage.resultsOneTextThree"),
          descriptionFour: this.$t("aboutpage.resultsOneTextFour"),
          descriptionFive: this.$t("aboutpage.resultsOneTextFive"),
        },
        {
          title: this.$t("aboutpage.resultsTwoTitle"),
          descriptionOne: this.$t("aboutpage.resultsTwoTextOne"),
          descriptionTwo: this.$t("aboutpage.resultsTwoTextTwo"),
          descriptionThree: this.$t("aboutpage.resultsTwoTextThree"),
          descriptionFour: "",
          descriptionFive: "",
        },
        {
          title: this.$t("aboutpage.resultsThreeTitle"),
          descriptionOne: this.$t("aboutpage.resultsThreeTextOne"),
          descriptionTwo: this.$t("aboutpage.resultsThreeTextTwo"),
          descriptionThree: "",
          descriptionFour: "",
          descriptionFive: "",
        },
      ],
    };
  },
};
</script>

<style scoped>
/* General Page */
.homePageWidth {
  width: 90vw;
  margin: auto auto;
}

/* About Company */
.aboutTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 48px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.aboutDescription {
  color: #6a6a6a;
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 18px;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.aboutKeyBoxes {
  margin: 0px;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
  font-family: "Lato", sans-serif;
  font-weight: normal;
  text-align: center;
  background-color: #e0f1ff;
  padding: 5px 10px 5px 10px;
  font-size: 12px;
}
.aboutImageBox {
  width: 100%;
  height: 100%;
  background-color: #d1d1d1;
  opacity: 1;
}

/* Goal */
.goalTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 34px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.goalDescription {
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.goalImage {
  width: 100%;
  height: 100%;
  background-color: #d1d1d1;
  opacity: 1;
}

/* For Whom */
.whomTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 34px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.whomDescription {
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.whomImage {
  width: 100%;
  height: 100%;
  background-color: #d1d1d1;
  opacity: 1;
}
.whomBackground {
  background-color: #f2f2f2;
  opacity: 1;
}

/* Why */
.whyTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 34px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.whyDescription {
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.whyImage {
  width: 100%;
  height: 100%;
  background-color: #d1d1d1;
  opacity: 1;
}
</style>
