<template>
  <div class="homePageWidth">
    <!-- <ResourcePage></ResourcePage> -->
    <!-- <ResourcePageTwo></ResourcePageTwo> -->
    <ResourcePageThree></ResourcePageThree>
  </div>
</template>

<script>
import ResourcePage from "@/components/Resource/ResourcePage.vue";
import ResourcePageTwo from "@/components/Resource/ResourcePageV2.vue";
import ResourcePageThree from "@/components/Resource/ResourcePageV3.vue";
export default {
  components: {
    ResourcePage,
    ResourcePageTwo,
    ResourcePageThree,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
/* General Page */
.homePageWidth {
  width: 100vw;
  margin: auto auto;
}
</style>
