import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-10"},[_c(VRow,[_c(VCol,{staticClass:"pt-15 mt-15 pb-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"rssHeadlinerTitle text-center"},[_vm._v(" "+_vm._s(_vm.$t("home.externalNewsTitle"))+" ")])]),_vm._l((_vm.randomList),function(item,index){return _c(VCol,{key:index,attrs:{"cols":"12","xl":"4","lg":"4","md":"4","sm":"12","xs":"12"}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c(VCard,{staticClass:"pa-5 pb-1",attrs:{"elevation":hover ? 12 : 2,"target":"_blank","height":"100%"},on:{"click":function($event){return _vm.goToRssLink(item.link[0])}}},[_c(VRow,[_c(VCol,{staticClass:"pb-0",attrs:{"cols":"12"}},[(_vm.$vuetify.breakpoint.width <= 959)?_c('p',{staticClass:"rssCardTitle",staticStyle:{"font-size":"16px","height":"20px"}},[_vm._v(" "+_vm._s(item.title[0])+" ")]):(
                  _vm.$vuetify.breakpoint.width <= 1366 &&
                  _vm.$vuetify.breakpoint.width >= 960
                )?_c('p',{staticClass:"rssCardTitle",staticStyle:{"font-size":"14px","height":"80px"}},[_vm._v(" "+_vm._s(item.title[0])+" ")]):_c('p',{staticClass:"rssCardTitle",staticStyle:{"font-size":"18px","height":"90px"}},[_vm._v(" "+_vm._s(item.title[0])+" ")]),_c('p',{staticClass:"rssDescriptionPre",domProps:{"innerHTML":_vm._s(item.description[0].replace(/<img[^>]*>/, ''))}})]),_c(VCol,{staticClass:"pt-0 mt-0 pb-0",attrs:{"cols":"12"}},[_c(VCardActions,{staticClass:"pt-0"},[_c('p',{staticClass:"rssCardButton mr-2 pt-3"},[_vm._v(" "+_vm._s(_vm.$t("home.readArticle"))+" ")]),_c(VIcon,{staticClass:"rssCardButtonArrow"},[_vm._v("mdi-arrow-right")])],1)],1),_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('p',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(item.link[0]))])])],1),_c(VDivider,{staticClass:"newsCardDividerPositioning",staticStyle:{"padding":"2px"},attrs:{"width":"98%"}})],1)]}}],null,true)})],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }