<template>
  <div>
    <v-btn
      @click="showCard"
      class="custom-fab"
      fab
      fixed
      bottom
      right
      style="background-color: #1565c0"
      v-if="pageReview !== 'true' && showThisThing"
    >
      <p class="pt-3" style="font-size: 12px">User experience of the webpage</p>
    </v-btn>

    <!-- Floating card dialog -->
    <v-dialog v-model="cardDialog" max-width="800" bottom right>
      <v-card
        class="pa-5"
        style="background-color: #f6f8f9; border-radius: 10px"
      >
        <p class="headline mb-5" style="font-weight: bold">
          Please help us improve this webpage by submitting your rating:
        </p>
        <v-container>
          <v-row v-for="(item, index) in userExperienceForm" :key="index">
            <v-col cols="12" class="pa-0">
              <p class="ma-0 pa-0">{{ item.label }}</p>
            </v-col>
            <v-col cols="12" class="ma-0 pa-0 mb-5">
              <v-row>
                <v-col cols="5" class="ma-0 pa-0 pt-2">
                  <v-rating
                    v-model="item.score"
                    background-color="grey darken-2"
                    color="orange"
                    :half-increments="false"
                    large
                  ></v-rating>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions class="ma-0 pa-0 mt-10">
          <v-btn
            @click="saveReview()"
            style="background-color: #234062; color: white"
          >
            Send Review
          </v-btn>
          <v-btn
            @click="hideCard"
            style="background-color: #595b5f; color: white"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cardDialog: false,
      accessKey: window.btoa("bac436b32a36431bb437b9509b6d3495"),
      pageReview: localStorage.getItem("pageReview"),
      showThisThing: true,
      userExperienceForm: [
        {
          label:
            "Rate the quality of the content of the webpage (1 = low : 5 = high)",
          score: 0,
        },
        {
          label:
            "Rate the accessibility and user experience of the webpage (1 = low : 5 = high)",
          score: 0,
        },
      ],
    };
  },
  methods: {
    // Show the card dialog and reset the scores to 0
    showCard() {
      this.cardDialog = true;
      this.userExperienceForm.forEach((item) => {
        item.score = 0;
      });
    },
    // Hide the card dialog
    hideCard() {
      this.cardDialog = false;
    },

    checkLocalStorage() {
      if (localStorage.getItem("pageReview") === null) {
        this.pageReview = false;
      } else {
        this.pageReview = localStorage.getItem("pageReview");
      }
    },

    // Send to localstorage pageReview to True, so that the review card is not shown again.  Create the key and set to true if it does not exist.
    sendToLocalStorage() {
      console.log("triggered");
      this.showThisThing = false;
      if (localStorage.getItem("pageReview") === null) {
        localStorage.setItem("pageReview", true);
      } else {
        localStorage.setItem("pageReview", true);
      }
      this.hideCard();
    },
    // Save the review
    saveReview() {
      // You can handle saving the review data here
      const pageReviewData = {
        tenant_id: 108,
        name: "Inprofuture",
        text: `${this.userExperienceForm[0].label} --- ${this.userExperienceForm[1].label}`,
        response: `${this.userExperienceForm[0].score} --- ${this.userExperienceForm[1].score}`,
      };
      this.$http
        .post(
          "https://app.followup.prios.no/api/resource_management/feedback",
          pageReviewData,
          { headers: { Tempaccess: this.accessKey } }
        )
        .then(() => {
          console.log("Sent");
          this.cardDialog = false;
          this.pageReview = true;
          location.reload();
        })
        .catch((error) => {
          console.error(error);
        });
      this.sendToLocalStorage();
    },
  },
  mounted() {
    this.checkLocalStorage();
  },
};
</script>

<style scoped>
.custom-fab {
  width: 300px;
  height: 60px;
  border-radius: 8px;
  background-color: #2196f3; /* Change the background color as needed */
  color: #ffffff; /* Change the text color as needed */
}

.custom-fab:hover {
  background-color: #1565c0; /* Change the hover background color as needed */
}
</style>
