import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"600"},model:{value:(_vm.invitationEventDialog),callback:function ($$v) {_vm.invitationEventDialog=$$v},expression:"invitationEventDialog"}},[_c(VCard,{staticClass:"pa-5"},[_c(VRow,{staticClass:"ma-0"},[_c(VCol,{attrs:{"cols":"10"}},[_c('p',{staticClass:"title text"},[_vm._v("Inprofuture Event")])]),_c(VCol,{staticClass:"d-flex justify-end ma-0 pa-0",attrs:{"cols":"1"}},[_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-close")])],1)],1)],1),_c(VRow,[_c(VCol,[_c('p',[_vm._v(" Everyone is welcome to join us on 7 March at 9:00 am at Opera Café in Budapest, where we will present the main results and experiences of the INPROFUTURE Project. ")]),_c('p',[_vm._v(" Please send an e-mail to "),_c('a',{attrs:{"href":'mailto:' + _vm.email}},[_vm._v(_vm._s(_vm.email))]),_vm._v(" by 5 March to register your interest to participate ")]),_c(VDivider,{staticClass:"my-4"}),_c(VImg,{staticClass:"ml-1 mb-5",attrs:{"src":require("@/assets/images/eventinvitation.png"),"alt":"Inprofuture Event Invitation","max-height":"auto","contain":""}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }