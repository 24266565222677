<template>
  <div>
    <v-row>
      <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12"></v-col>
      <v-col
        cols="12"
        xl="10"
        lg="10"
        md="10"
        sm="12"
        xs="12"
        class="ma-0 pa-0 pt-16 mt-16"
      >
        <v-card class="ma-0 pa-0 box" flat>
          <p class="ma-0 pa-0 partnerChapterTitle">
            {{ $t("home.partnerPageTitle") }}
          </p>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        xl="6"
        lg="6"
        md="6"
        sm="12"
        xs="12"
        v-for="(item, index) in projectPartners"
        :key="index"
      >
        <v-card class="cardBorder ma-2 pa-7 px-2" height="100%">
          <v-row>
            <v-col cols="12">
              <v-card
                height="90px"
                flat
                class="d-flex"
                :href="item.link"
                target="_blank"
              >
                <v-img class="mt-5" :src="item.image" contain />
              </v-card>
            </v-col>
            <v-col class="pb-0 mb-0" cols="12">
              <p class="pl-3 partnerTitle">
                {{ item.title }}
                <span>
                  <v-btn icon :href="item.link" target="_blank">
                    <v-icon color="green">mdi-open-in-new</v-icon>
                  </v-btn>
                </span>
              </p>
            </v-col>
            <v-col
              v-if="selectedIndex !== index"
              cols="12"
              class="ma-0 pa-0 pl-3 pr-3"
            >
              <p class="pl-3 pr-3 partnerDescriptionPre">
                {{ item.descriptionOne }}
              </p>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              icon
              v-if="selectedIndex !== index"
              @click="openingPartnerExpand(index)"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
            <v-btn icon v-else @click="closingPartnerExpand()">
              <v-icon large color="black">mdi-chevron-up</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="index === selectedIndex">
              <v-divider></v-divider>
              <v-card-text>
                <p class="partnerDescriptionPost ma-0 pa-0">
                  {{ item.descriptionOne }}
                </p>
                <p class="partnerDescriptionPost ma-0 pa-0">
                  {{ item.descriptionTwo }}
                </p>
                <p class="partnerDescriptionPost ma-0 pa-0">
                  {{ item.descriptionThree }}
                </p>
                <p class="partnerDescriptionPost ma-0 pa-0">
                  {{ item.descriptionFour }}
                </p>
                <p class="partnerDescriptionPost ma-0 pa-0">
                  {{ item.descriptionFive }}
                </p>
                <p class="partnerDescriptionPost ma-0 pa-0">
                  {{ item.descriptionSix }}
                </p>
                <p class="partnerDescriptionPost ma-0 pa-0">
                  {{ item.descriptionSeven }}
                </p>
                <p class="partnerDescriptionPost ma-0 pa-0">
                  {{ item.descriptionEight }}
                </p>
                <p class="partnerDescriptionPost ma-0 pa-0">
                  {{ item.descriptionNine }}
                </p>
                <p class="partnerDescriptionPost ma-0 pa-0">
                  {{ item.descriptionTen }}
                </p>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedIndex: null,
      projectPartners: [
        {
          title: "Progress Consult",
          image: "img/PC_logo_angol-01.png",
          link: "http://progress.hu/",
          descriptionOne: this.$t("partnerPage.progressConsultTextOne"),
          descriptionTwo: this.$t("partnerPage.progressConsultTextTwo"),
          descriptionThree: this.$t("partnerPage.progressConsultTextThree"),
          descriptionFour: this.$t("partnerPage.progressConsultTextFour"),
          descriptionFive: this.$t("partnerPage.progressConsultTextFive"),
          descriptionSix: "",
          descriptionSeven: "",
          descriptionEight: "",
          descriptionNine: "",
          descriptionTen: "",
        },
        {
          title: "Prios Kompetanse",
          image: "img/prioslogo_PNG_big_dark.png",
          link: "https://www.prios.no/",
          descriptionOne: this.$t("partnerPage.priosTextOne"),
          descriptionTwo: this.$t("partnerPage.priosTextTwo"),
          descriptionThree: this.$t("partnerPage.priosTextThree"),
          descriptionFour: this.$t("partnerPage.priosTextFour"),
          descriptionFive: this.$t("partnerPage.priosTextFive"),
          descriptionSix: this.$t("partnerPage.priosTextSix"),
          descriptionSeven: "",
          descriptionEight: "",
          descriptionNine: "",
          descriptionTen: "",
        },
        {
          title: "Slovenska inovacna a energeticka agentura",
          image: "img/siea-logo.png",
          link: "https://www.siea.sk/",
          descriptionOne: this.$t("partnerPage.sieaTextOne"),
          descriptionTwo: this.$t("partnerPage.sieaTextTwo"),
          descriptionThree: this.$t("partnerPage.sieaTextThree"),
          descriptionFour: this.$t("partnerPage.sieaTextFour"),
          descriptionFive: this.$t("partnerPage.sieaTextFive"),
          descriptionSix: this.$t("partnerPage.sieaTextSix"),
          descriptionSeven: this.$t("partnerPage.sieaTextSeven"),
          descriptionEight: this.$t("partnerPage.sieaTextEight"),
          descriptionNine: this.$t("partnerPage.sieaTextNine"),
          descriptionTen: this.$t("partnerPage.sieaTextTen"),
        },
      ],
    };
  },
  methods: {
    // Open the Expand
    openingPartnerExpand(index) {
      this.selectedIndex = index;
    },
    // Close the Expand
    closingPartnerExpand() {
      this.selectedIndex = null;
    },
  },
};
</script>

<style scoped>
/* Card CSS */
.box {
  border-bottom: 3px solid transparent;
  border-image: linear-gradient(to right, #dd9a30 5%, transparent 5%);
  border-image-slice: 1;
}
.cardBorder {
  border: 3px #47b5b0 solid;
}

/* Text styling */
.partnerChapterTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 46px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.partnerTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 24px;
  color: #141428;
  letter-spacing: 0px;
  text-align: left;
}
.partnerDescriptionPre {
  font-family: "Lato", sans-serif;
  font-weight: normal;
  text-align: left;
  color: #141428;
  opacity: 1;
  letter-spacing: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.partnerDescriptionPost {
  font-family: "Lato", sans-serif;
  font-weight: normal;
  text-align: left;
  color: #141428;
  opacity: 1;
  opacity: 1;
  letter-spacing: 0px;
}
</style>
