<template>
  <div>
    <ResourceDialog ref="openingResourceDialog"></ResourceDialog>
    <ResourceReview></ResourceReview>
    <v-row class="justify-center">
      <v-col cols="12" class="mt-10"></v-col>
      <v-col cols="12">
        <p class="text-center title mt-10">{{ $t("home.resources") }}</p>
      </v-col>
    </v-row>

    <!-- <pre>{{ manualResources }}</pre> -->

    <!-- <p>{{ language }}</p> -->

    <!-- Clickable Cards -->
    <v-row class="justify-center">
      <v-col cols="8" class="mt-10 mb-10">
        <v-row>
          <v-col
            cols="12"
            xl="4"
            lg="6"
            md="6"
            sm="12"
            xs="12"
            v-for="resourceCard in resourceCards"
            :key="resourceCard.templateID"
          >
            <v-hover v-slot:default="{ hover }">
              <v-card
                @click="changeTemplate(resourceCard.templateID)"
                :elevation="hover ? 12 : 2"
              >
                <p class="text-center title mb-0 py-10">
                  {{ resourceCard.label }}
                </p>
                <v-divider
                  class="newsCardDividerPositioning"
                  width="100%"
                  :style="`background-color:${
                    colorArr[resourceCard.templateID]
                  }`"
                ></v-divider>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Template based on card clicked -->
    <v-row v-if="templateID !== 0" class="mb-10">
      <v-col
        cols="12"
        v-for="resource in filteredResources"
        :key="resource.templateID"
      >
        <v-divider class="my-10"></v-divider>
        <p class="text-center title mb-10">{{ resource.title }}</p>

        <!-- Type Icon, label, buttons based on content -->
        <v-row>
          <v-col
            cols="10"
            class="mx-auto"
            v-for="content in resource.content"
            :key="content.topic"
          >
            <v-row>
              <v-col
                cols="12"
                class="pb-0 pt-10"
                v-if="resource.templateID == 1"
              >
                <p class="text-center title">{{ content.topic }}</p>
              </v-col>
              <v-col
                cols="12"
                v-for="tool in content.tools"
                :key="tool.label"
                class=""
              >
                <!-- Check if the user's language is in the tool's languages array -->
                <v-row
                  v-if="tool.languages.includes(language)"
                  class="border-bottom"
                >
                  <v-col cols="2" xl="1" lg="1" md="1" sm="2" xs="2">
                    <v-icon large :color="getToolColor(tool.type)">{{
                      getToolIcon(tool.type)
                    }}</v-icon>
                  </v-col>
                  <v-col
                    cols="8"
                    xl="8"
                    lg="8"
                    md="8"
                    sm="8"
                    xs="8"
                    class="mt-1 pl-0"
                  >
                    {{ tool.label }}
                  </v-col>
                  <v-col
                    cols="2"
                    xl="3"
                    lg="3"
                    md="3"
                    sm="2"
                    xs="2"
                    :class="{
                      'd-flex justify-end align-center':
                        $vuetify.breakpoint.mdAndUp,
                      'text-right': $vuetify.breakpoint.mdAndUp ? true : false,
                    }"
                  >
                    <v-btn @click="handleButtonClick(tool)" icon class="mr-5">
                      <v-icon color="blue">{{
                        getButtonIcon(tool.type)
                      }}</v-icon>
                    </v-btn>
                    <v-btn @click="openMoreWindow(content, tool)" icon>
                      <v-icon color="green">mdi-information-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ResourceDialog from "@/components/dialogs/resourceDialog.vue";
import ResourceReview from "@/components/Resource/ResourceReview.vue";
export default {
  components: {
    ResourceDialog,
    ResourceReview,
  },
  data() {
    return {
      // Color array for the cards
      colorArr: [
        "#205072",
        "#329D9C",
        "#D83636",
        "#DD9A30",
        "#205072",
        "#329D9C",
      ],
      // Color array for sub cards
      subColorArr: ["#329D9C", "#D83636", "#DD9A30"],

      accessKey: window.btoa("bac436b32a36431bb437b9509b6d3495"),

      // Get Language from localstorage
      language: localStorage.getItem("language"),

      /* TemplateID used on card clicks
        0 = no template - Default
        1 = Digital tools and platforms
        2 = Case studies and research
        3 = Training and professional development
        4 = Policy and funding opportunities
      */
      templateID: 0,

      // Clickable Cards - i18n
      resourceCards: [
        {
          label: this.$t("resources.video"),
          templateID: 1,
        },
        {
          label: this.$t("resources.files"),
          templateID: 2,
        },
        {
          label: this.$t("resources.courses"),
          templateID: 3,
        },
        {
          label: this.$t("resources.podcast"),
          templateID: 4,
        },
      ],

      // Resources to display in the various templates
      manualResources: [
        {
          templateID: 1,
          title: this.$t("resources.video"),
          content: [
            {
              topic: this.$t("resources.productServiceDesignTitle"),
              description: this.$t("resources.productServiceDesignDescription"),
              tools: [
                {
                  label: "Invision",
                  link: "https://www.invisionapp.com/",
                  colourID: 0,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                  benefitText: "",
                },
                {
                  label: "Sketch",
                  link: "https://www.sketch.com/",
                  colourID: 1,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                  benefitText: "",
                },
                {
                  label: "Adobe XD",
                  link: "https://www.adobe.com/products/xd.html",
                  colourID: 2,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                  benefitText: "",
                },
              ],
            },
            {
              topic: this.$t("resources.ProjectPlanningAndManagementTitle"),
              description: this.$t(
                "resources.ProjectPlanningAndManagementDescription"
              ),
              tools: [
                {
                  label: "Trello",
                  link: "https://trello.com/",
                  colourID: 0,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                  benefitText: "",
                },
                {
                  label: "Asana",
                  link: "https://asana.com/",
                  colourID: 1,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                  benefitText: "",
                },
                {
                  label: "Microsoft Project",
                  link: "https://www.microsoft.com/en-us/microsoft-365/project/project-management-software",
                  colourID: 2,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                  benefitText: "",
                },
              ],
            },
            {
              topic: this.$t("resources.operationsTitle"),
              description: this.$t("resources.operationsDescription"),
              tools: [
                {
                  label: "Fishbowl",
                  link: "https://www.fishbowlinventory.com/",
                  colourID: 0,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                },
                {
                  label: "Infor CloudSuite Industrial",
                  link: "https://www.infor.com/products/cloudsuite-industrial",
                  colourID: 1,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                },
                {
                  label: "NetSuite",
                  link: "https://www.netsuite.com/portal/products/erp/industries/manufacturing",
                  colourID: 2,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                },
              ],
            },
            {
              topic: this.$t(
                "resources.collaborationWithOtherInternalSiteLocationTitle"
              ),
              description: this.$t(
                "resources.collaborationWithOtherInternalSiteLocationDescription"
              ),
              tools: [
                {
                  label: "Slack",
                  link: "https://slack.com/",
                  colourID: 0,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                },
                {
                  label: "Microsoft Teams",
                  link: "https://www.microsoft.com/en-us/microsoft-teams/group-chat-software",
                  colourID: 1,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                },
                {
                  label: "Zoom",
                  link: "https://zoom.us/",
                  colourID: 2,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                },
              ],
            },
            {
              topic: this.$t("resources.inboundLogisticsAndWarehousingTitle"),
              description: this.$t(
                "resources.inboundLogisticsAndWarehousingDescription"
              ),
              tools: [
                {
                  label: "SAP Logistics",
                  link: "https://www.sap.com/products/supply-chain-logistics.html",
                  colourID: 0,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                },
                {
                  label: "Oracle Warehouse Management Cloud",
                  link: "https://www.oracle.com/applications/supply-chain-management/warehouse-management-cloud/",
                  colourID: 1,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                },
                {
                  label: "Odoo Inventory",
                  link: "https://www.odoo.com/page/warehouse",
                  colourID: 2,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                },
              ],
            },
            {
              topic: this.$t(
                "resources.marketingSalesAndCustomerServicesTitle"
              ),
              description: this.$t(
                "resources.marketingSalesAndCustomerServicesDescription"
              ),
              tools: [
                {
                  label: "HubSpot",
                  link: "https://www.hubspot.com/",
                  colourID: 0,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                },
                {
                  label: "Salesforce",
                  link: "https://www.salesforce.com/",
                  colourID: 1,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                },
                {
                  label: "Zendesk",
                  link: "https://www.zendesk.com/",
                  colourID: 2,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                },
              ],
            },
            {
              topic: this.$t("resources.deliveryTitle"),
              description: this.$t("resources.deliveryDescription"),
              tools: [
                {
                  label: "ShipStation",
                  link: "https://www.shipstation.com/",
                  colourID: 0,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                },
                {
                  label: "QuickBooks",
                  link: "https://quickbooks.intuit.com/",
                  colourID: 1,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                },
                {
                  label: "Xero",
                  link: "https://www.xero.com/",
                  colourID: 2,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                },
              ],
            },
            {
              topic: this.$t("resources.administrationAndHumanResourcesTitle"),
              description: this.$t(
                "resources.administrationAndHumanResourcesDescription"
              ),
              tools: [
                {
                  label: "BambooHR",
                  link: "https://www.bamboohr.com/",
                  colourID: 0,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                },
                {
                  label: "Workday",
                  link: "https://www.workday.com/",
                  colourID: 1,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                },
                {
                  label: "ADP",
                  link: "https://www.adp.com/",
                  colourID: 2,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                },
              ],
            },
            {
              topic: this.$t("resources.purchasingAndProcurementTitle"),
              description: this.$t(
                "resources.purchasingAndProcurementDescription"
              ),
              tools: [
                {
                  label: "Coupa",
                  link: "https://www.coupa.com/",
                  colourID: 0,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                },
                {
                  label: "Procurify",
                  link: "https://www.procurify.com/",
                  colourID: 1,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                },
                {
                  label: "Ariba Network",
                  link: "https://www.ariba.com/ariba-network",
                  colourID: 2,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                },
              ],
            },
            {
              topic: this.$t("resources.cybersecurityTitle"),
              description: this.$t("resources.cybersecurityDescription"),
              tools: [
                {
                  label: "NortonLifeLock",
                  link: "https://www.nortonlifelock.com/",
                  colourID: 0,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                },
                {
                  label: "McAfee",
                  link: "https://www.mcafee.com/",
                  colourID: 1,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                },
                {
                  label: "OneTrust",
                  link: "https://www.onetrust.com/",
                  colourID: 2,
                  type: "external_content",
                  languages: ["en", "nb", "sk", "hu"],
                },
              ],
            },
          ],
        },
        {
          templateID: 2,
          title: this.$t("resources.files"),
          content: [
            {
              topic:
                "What now for the digitalisation of small- and medium-sized enterprises",
              description:
                "The COVID-19 pandemic may have been a “shot in the arm” as far as rapid digitalisation of SMEs is concerned, but several challenges remain regarding digital infrastructure, skills, digital security",
              tools: [
                {
                  label:
                    "What now for the digitalisation of small- and medium-sized enterprises",
                  link: "https://www.youtube.com/embed/FZb-_tlgaZQ",
                  colourID: 0,
                  type: "youtube_video",
                  thumbnail_url:
                    "http://i3.ytimg.com/vi/FZb-_tlgaZQ/hqdefault.jpg",
                  languages: ["en", "nb", "sk", "hu"],
                  benefitText: "",
                },
              ],
            },
            {
              topic: "KPMG global tech report 2022",
              description:
                "The KPMG Global Tech Report 2022 offers an in-depth analysis on how embracing digital transformation can lead to significant benefits for businesses, including enhanced efficiency and customer satisfaction, underscoring the importance of investing in technology to stay competitive in today's fast-paced market.",
              tools: [
                {
                  label: "KPMG global tech report 2022",
                  link: "https://kpmg.com/xx/en/home/insights/2022/09/kpmg-global-tech-report-2022.html",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url:
                    "https://kpmg.com/xx/en/home/insights/2022/09/kpmg-global-tech-report-2022.html",
                  languages: ["en"],
                  benefitText: "",
                },
              ],
            },
            {
              topic:
                "Digital Transformation in Hungary: Embracing Innovation in the Heart of Europe",
              description:
                "The article on Flevy's blog provides insights into Hungary's journey towards digital innovation, highlighting government initiatives, the burgeoning start-up ecosystem, and the strategic importance of digital skills development to propel the nation into a leading position in Central Europe's digital landscape.",
              tools: [
                {
                  label:
                    "Digital Transformation in Hungary: Embracing Innovation in the Heart of Europe",
                  link: "https://flevy.com/blog/digital-transformation-in-hungary-embracing-innovation-in-the-heart-of-europe/",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url:
                    "https://flevy.com/blog/digital-transformation-in-hungary-embracing-innovation-in-the-heart-of-europe/",
                  languages: ["en"],
                  benefitText: "",
                },
              ],
            },
            {
              topic:
                "Právny rámec, ktorý definuje politiku a konkrétne priority Slovenska v kontexte prebiehajúcej digitálnej transformácie",
              description:
                "Základným poslaním tejto stratégie je nielen dosiahnuť výrazné zvýšenie zapojenia Slovenska do európskeho jednotného digitálneho trhu, ale predovšetkým pripraviť Slovensko na celoštátnu digitálna transformácia ekonomiky a spoločnosti.",
              tools: [
                {
                  label: "Stratégia digitálnej transformácie Slovenska 2030:",
                  link: "https://mirri.gov.sk/wp-content/uploads/2019/06/Strategia-digitalnej-transformacie-Slovenska-2030.pdf",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "",
                  languages: ["sk"],
                  benefitText: "",
                },
              ],
            },
            {
              topic:
                "Znalosť digitálnych zručností na primeranej úrovni ako predpoklad pre uplatnenie jednotlivca na neustále sa meniacom trhu práce",
              description:
                "Digitalizácia ovplyvňuje životy všetkých občanov bez ohľadu na vek, pohlavie či geografickú polohu. Preto je potrebné pochopiť tento proces a pripraviť sa na to.",
              tools: [
                {
                  label:
                    "Národná stratégia digitálnych zručností slovenskej republiky a akčný plán na roky 2023 – 2026:",
                  link: "https://mirri.gov.sk/wp-content/uploads/2023/01/NSDZ-a-AP.pdf",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "",
                  languages: ["sk"],
                  benefitText: "",
                },
              ],
            },
            {
              topic:
                "Zodpovednosť za riziká, ktoré vznikajú paralelne ako negatívna daň za výhody digitálnej spoločnosti",
              description:
                "Ucelený koncept riadenia informačnej a kybernetickej bezpečnosti, strategické smerovanie na základe jasných princípov a presne definované strategické ciele sú základom pre dobre vyvinutý systém, ktorý dokáže pružne reagovať na aktuálne hrozby a zabezpečiť tak vysokú mieru kybernetickej bezpečnosti na národnej úrovni.",
              tools: [
                {
                  label:
                    "Národná stratégia kybernetickej bezpečnosti na roky 2021 až 2025",
                  link: "https://www.nbu.gov.sk/wp-content/uploads/kyberneticka-bezpecnost/Narodna-strategia-kybernetickej-bezpecnosti.pdf",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "",
                  languages: ["sk"],
                },
              ],
            },
            {
              topic:
                "Vplyv robotizácie, automatizácie a digitalizácie na trh práce v SR. Výsledky empirického prieskumu",
              description:
                "Nové znalosti a dáta o miere vplyvu uplatnenia digitálnej transformácie a analýza pripravenosti na digitálnu transformáciu v priemyselných podnikoch na Slovensku z hľadiska dopadov na zamestnanosť a ľudské zdroje.",
              tools: [
                {
                  label:
                    "Vplyv robotizácie, automatizácie a digitalizácie na trh práce v SR:",
                  link: "https://ivpr.gov.sk/wp-content/uploads/2022/09/Vyskumna_sprava_digitalizacia.pdf",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "",
                  languages: ["sk"],
                },
              ],
            },
            {
              topic:
                "Ako fungujú politiky na podporu prijímania digitálnych technológií MSP v SR.",
              description:
                "Hodnotí úroveň digitálneho rozvoja MSP v Slovenskej republike a identifikuje silné a slabé stránky v porovnaní s inými krajinami. Hodnotí aj existujúce zásahy slovenskej vlády s cieľom umožniť MSP a podnikateľom digitalizáciu, vrátane opatrení na podporu MSP, ktoré sú už inovatívne a digitálne naladené, ako aj tých, ktoré zaostávajú.",
              tools: [
                {
                  label: "Iniciatívy digitalizácie MSP v Slovenskej republike:",
                  link: "https://www.oecd-ilibrary.org/sites/9a916e11-en/index.html?itemId=/content/component/9a916e11-en#section-d1e18797",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "",
                  languages: ["sk"],
                },
              ],
            },
            {
              topic: "Digitalisering av bedrifter",
              description: "",
              tools: [
                {
                  label: "Digitalisering av bedrifter",
                  link: "https://www.youtube.com/embed/wKkaqo8uAA8",
                  colourID: 0,
                  type: "youtube_video",
                  thumbnail_url:
                    "http://i3.ytimg.com/vi/wKkaqo8uAA8/hqdefault.jpg",
                  languages: ["nb"],
                },
              ],
            },
            {
              topic: "WEBINAR: Digitalisering fra ord til handling",
              description:
                "Er det viktig med digitalisering? Og hvordan skal man få det ut i live?",
              tools: [
                {
                  label: "WEBINAR: Digitalisering fra ord til handling",
                  link: "https://www.youtube.com/embed/QBcpfhERgic",
                  colourID: 0,
                  type: "youtube_video",
                  thumbnail_url:
                    "http://i3.ytimg.com/vi/QBcpfhERgic/hqdefault.jpg",
                  languages: ["nb"],
                },
              ],
            },
            {
              topic: "Trønderske virksomheter best i landet på digitalisering",
              description:
                "8 av 10 små og mellomstore bedrifter (SMB) i Trøndelag gjennomfører tiltak for å digitalisere virksomheten.",
              tools: [
                {
                  label:
                    "Trønderske virksomheter best i landet på digitalisering",
                  link: "https://kommunikasjon.ntb.no/pressemelding/tronderske-virksomheter-best-i-landet-pa-digitalisering?publisherId=17847141&releaseId=17936831",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url:
                    "https://kommunikasjon.ntb.no/pressemelding/tronderske-virksomheter-best-i-landet-pa-digitalisering?publisherId=17847141&releaseId=17936831",
                  languages: ["nb"],
                },
              ],
            },
            {
              topic: "DigitalNorway",
              description:
                "Digitaliseringen av norsk næringsliv, med et spesielt fokus på små og mellomstore bedrifte",
              tools: [
                {
                  label: "DigitalNorway",
                  link: "https://digitalnorway.com/",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "https://digitalnorway.com/",
                  languages: ["nb"],
                },
              ],
            },
            {
              topic: "Kutatás: megéri a cégeknek a digitális átállás",
              description:
                "A KPMG Global Tech Report 2022 összefoglalója, amely arra fókuszál, hogy milyen előnyökkel jár a digitális átállás a vállalkozások számára és miért érdemes befektetni ebbe az irányba.",
              tools: [
                {
                  label: "Kutatás: megéri a cégeknek a digitális átállás",
                  link: "https://infostart.hu/gazdasag/2023/03/17/kutatas-megeri-a-cegeknek-a-digitalis-atallas",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url:
                    "https://infostart.hu/gazdasag/2023/03/17/kutatas-megeri-a-cegeknek-a-digitalis-atallas",
                  languages: ["hu"],
                },
              ],
            },
            {
              topic: "Digitális fordulat jöhet a KKV szektorban",
              description:
                "A Microsoft Magyarország híroldalán megjelent cikk bemutatja, hogy a kis- és középvállalkozások szektorában milyen digitális fordulat várható, kiemelve azokat a technológiai innovációkat és digitális megoldásokat, amelyek elősegíthetik ezeknek a vállalkozásoknak a növekedését és versenyképességének javítását a digitális korban",
              tools: [
                {
                  label: "Digitális fordulat jöhet a KKV szektorban",
                  link: "https://news.microsoft.com/hu-hu/2022/05/17/digitalis-fordulat-johet-a-kkv-szektorban/",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url:
                    "https://news.microsoft.com/hu-hu/2022/05/17/digitalis-fordulat-johet-a-kkv-szektorban/",
                  languages: ["hu"],
                },
              ],
            },
            {
              topic:
                "Új megoldásokra van szükség a hazai cégek digitális stratégiájában",
              description:
                "A Digital Hungary-n megjelent cikk kifejti, hogy a hazai vállalatok digitális stratégiájában miért elengedhetetlen az új megoldások integrálása, és hogy ez milyen pozitív hatással lehet a vállalkozások innovációs képességére és piaci helyzetére a folyamatosan változó digitális gazdaságban.",
              tools: [
                {
                  label:
                    "Új megoldásokra van szükség a hazai cégek digitális stratégiájában",
                  link: "https://www.digitalhungary.hu/e-volution/uj-megoldasokra-van-szukseg-a-hazai-cegek-digitalis-strategiajaban/17132/",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url:
                    "https://www.digitalhungary.hu/e-volution/uj-megoldasokra-van-szukseg-a-hazai-cegek-digitalis-strategiajaban/17132/",
                  languages: ["hu"],
                },
              ],
            },
            {
              topic: "Digitális átállás: néha nem árt egyet hátra lépni",
              description:
                "A Deloitte elemzése rávilágít arra, hogy az adatvezérelt vállalatok milyen előnyöket és kihívásokat tapasztalnak a digitális transzformáció során, hangsúlyozva az adatgazdálkodás stratégiai fontosságát a kockázatok kezelésében és az üzleti döntéshozatalban.",
              tools: [
                {
                  label: "Digitális átállás: néha nem árt egyet hátra lépni",
                  link: "https://www2.deloitte.com/hu/hu/pages/kockazat/articles/risk-respond--8-Az-adatvezerelt-vallalatok-vilagaban.html",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url:
                    "https://www2.deloitte.com/hu/hu/pages/kockazat/articles/risk-respond--8-Az-adatvezerelt-vallalatok-vilagaban.html",
                  languages: ["hu"],
                },
              ],
            },
            {
              topic: "Íme, 2024 legfontosabb digitalizációs trendjei",
              description:
                "A Trade Magazin cikke előre tekint 2024 legfontosabb digitalizációs trendjeire, beleértve a mesterséges intelligencia, felhőalapú technológiák és a kiberbiztonság területén várható innovációkat, amelyek meghatározóak lesznek a vállalkozások számára a következő évben, elősegítve ezzel üzleti tevékenységük modernizálását és piaci pozíciójuk erősítését.",
              tools: [
                {
                  label: "Íme, 2024 legfontosabb digitalizációs trendjei",
                  link: "https://trademagazin.hu/hu/ime-2024-legfontosabb-digitalizacios-trendjei/",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url:
                    "https://trademagazin.hu/hu/ime-2024-legfontosabb-digitalizacios-trendjei/",
                  languages: ["hu"],
                },
              ],
            },
            {
              topic:
                "Mi a sikeres vállalati transzformáció kulcsa? Miért kezdenek cégek transzformációba és mire kell figyelni a sikeresség érdekében?",
              description:
                "A Deloitte által közzétett elemzés kulcsfontosságú betekintést nyújt a sikeres vállalati transzformáció lényegére, kiemelve, hogy a stratégiai tervezés, az erőforrások hatékony kezelése, és a technológiai innovációk integrálása elengedhetetlen a vállalatok hosszú távú sikeréhez és alkalmazkodóképességéhez a gyorsan változó üzleti környezetben.",
              tools: [
                {
                  label:
                    "Mi a sikeres vállalati transzformáció kulcsa? Miért kezdenek cégek transzformációba és mire kell figyelni a sikeresség érdekében?",
                  link: "https://www2.deloitte.com/hu/hu/pages/technologia/articles/mi-a-sikeres-vallalati-transzformacio-kulcsa.html",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url:
                    "https://www2.deloitte.com/hu/hu/pages/technologia/articles/mi-a-sikeres-vallalati-transzformacio-kulcsa.html",
                  languages: ["hu"],
                },
              ],
            },
            {
              topic:
                "7 tipp a munkavállalók digitális készségeinek fejlesztéséhez",
              description:
                "A Blue Colibri App blogbejegyzése konkrét tippeket sorol fel arról, hogyan lehet javítani az alkalmazottak digitális készségeit, hangsúlyozva, hogy a folyamatos tanulás és fejlesztés kulcsfontosságú a digitális kor kihívásainak megfeleléséhez, így erősítve a vállalatok belső innovációs képességét és piaci versenyképességét.",
              tools: [
                {
                  label:
                    "7 tipp a munkavállalók digitális készségeinek fejlesztéséhez",
                  link: "https://hu.bluecolibriapp.com/blog/7-tips-for-improving-the-digital-skills-of-your-employees",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url:
                    "https://hu.bluecolibriapp.com/blog/7-tips-for-improving-the-digital-skills-of-your-employees",
                  languages: ["hu"],
                },
              ],
            },
            {
              topic: "A digitális kompetenciáról",
              description:
                "A Digitális Pedagógiai Módszertani Központ cikke kiemeli a digitális kompetencia fontosságát a 21. századi tudástársadalomban, rámutatva, hogy az UNESCO az alapvető emberi jogok közé sorolja, ami aláhúzza ennek a képességnek a jelentőségét mind az egyének, mind a vállalatok számára a digitális korban.",
              tools: [
                {
                  label: "A digitális kompetenciáról",
                  link: "https://dpmk.hu/2019/06/18/a-digitalis-kompetenciarol/",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url:
                    "https://dpmk.hu/2019/06/18/a-digitalis-kompetenciarol/",
                  languages: ["hu"],
                },
              ],
            },
          ],
        },
        {
          templateID: 3,
          title: this.$t("resources.courses"),
          content: [
            // https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/ae591fc4-6ba9-440c-9a03-05eea0aca733.pdf
            {
              topic: "Inprofuture Templates",
              description: "Inprofuture Templates",
              tools: [
                {
                  label: "Inprofuture Handbook",
                  link: "https://anyflip.com/whhx/anaz/",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "",
                  languages: ["nb", "sk", "en", "de", "hu"],
                  extraLink: "https://anyflip.com/whhx/jkrw/",
                },
              ],
            },
            {
              topic: "IKT-Huset",
              description:
                "Basisopplæring data - Flere kurs innen grunnleggende bruk av data",
              tools: [
                {
                  label: "IKT-Huset",
                  link: "https://www.ikt-huset.no/dataopplaering/",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "",
                  languages: ["nb"],
                },
              ],
            },
            {
              topic: "Firebrand",
              description: "Kurs innen en rekke plattformer og systemer.",
              tools: [
                {
                  label: "Firebrand",
                  link: "https://firebrand.training/no/kurs/artificial-intelligence-big-data",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "",
                  languages: ["nb"],
                },
              ],
            },
            {
              topic: "DigitalNorway",
              description:
                "Non-profitt aktør stiftet av næringslivet for å få fart på digitaliseringen av norsk næringsliv. En rekke kurs, bade fysiske og digitale kurstilbud.",
              tools: [
                {
                  label: "DigitalNorway",
                  link: "https://digitalnorway.com/kurs/",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "",
                  languages: ["nb"],
                },
              ],
            },
            {
              topic: this.$t("resources.companyDigitzationTitle"),
              description: this.$t("resources.companyDigitzationDescription"),
              tools: [
                {
                  label: this.$t("resources.companyDigitzationToolLabelOne"),
                  link: "https://skillmea.sk/online-kurzy/upterdam-2023?active_section=abaf2ed9-d042-4605-b56a-7750522b4d2f#01-lubomir-vesely-grit",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "",
                  languages: ["sk"],
                },
              ],
            },
            {
              topic: this.$t("resources.companyDigitzationTitle"),
              description: this.$t("resources.companyDigitzationDescription"),
              tools: [
                {
                  label: this.$t("resources.companyDigitzationToolLabelTwo"),
                  link: "https://www.youtube.com/watch?v=4blNqE9Eq0c",
                  colourID: 0,
                  type: "youtube_video",
                  thumbnail_url: "",
                  languages: ["sk"],
                },
              ],
            },
            {
              topic: this.$t("resources.implementationInnovationTitle"),
              description: this.$t(
                "resources.implementationInnovationDescription"
              ),
              tools: [
                {
                  label: this.$t(
                    "resources.implementationInnovationToolLabelOne"
                  ),
                  link: "https://hopero.sk/eventy/meetlearn-digitalizacia-hr-oddelenia/",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "",
                  languages: ["sk"],
                },
              ],
            },
            {
              topic: this.$t("resources.usingArtificialIntelligenceTitle"),
              description: this.$t(
                "resources.usingArtificialIntelligenceDescription"
              ),
              tools: [
                {
                  label: this.$t(
                    "resources.usingArtificialIntelligenceToolLabelOne"
                  ),
                  link: "https://www.scdi.tech/ako-nasadit-umelu-inteligenciu-aj-vo-vasej-firme-pozrite-si-tychto-8-praktickych-scenarov/",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "",
                  languages: ["sk"],
                },
              ],
            },
            {
              topic: this.$t("resources.usingArtificialIntelligenceTitle"),
              description: this.$t(
                "resources.usingArtificialIntelligenceDescription"
              ),
              tools: [
                {
                  label: this.$t(
                    "resources.usingArtificialIntelligenceToolLabelTwo"
                  ),
                  link: "https://www.scdi.tech/video-chcete-nasadit-chatboty-a-umelu-inteligenciu-vo-vasej-firme-specialisti-na-marketing-a-ai-prezradili-ako-na-to/",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "",
                  languages: ["sk"],
                },
              ],
            },
            {
              topic: this.$t("resources.introducingPublicToSmartTechTitle"),
              description: this.$t(
                "resources.introducingPublicToSmartTechDescription"
              ),
              tools: [
                {
                  label: this.$t(
                    "resources.introducingPublicToSmartTechToolLabelOne"
                  ),
                  link: "https://www.expandi40.sk/roboticke-vikendy-s-nacero/",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "",
                  languages: ["sk"],
                },
              ],
            },
            {
              topic: this.$t("resources.preparingStudentsProffessionTitle"),
              description: this.$t(
                "resources.preparingStudentsProffessionDescription"
              ),
              tools: [
                {
                  label: this.$t(
                    "resources.preparingStudentsProffessionToolLabelOne"
                  ),
                  link: "https://www.expandi40.sk/michalovski-gymnazisti-zazili-v-testbede-4-0-unikatny-bootcamp/",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "",
                  languages: ["sk"],
                },
              ],
            },
            {
              topic: this.$t("resources.adviceOnlineEventSlovakBusiness"),
              description: this.$t(
                "resources.adviceOnlineEventSlovakBusinessDescription"
              ),
              tools: [
                {
                  label: this.$t(
                    "resources.adviceOnlineEventSlovakBusinessToolLabelOne"
                  ),
                  link: "https://www.inovujme.sk/sk/clanok/ake-prispevky-na-inoavcie-budu-k-dispozicii-siea-pozyva-podnikatelov-na-online-podujatie-kde-sa-dozvedia-viac",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "",
                  languages: ["sk"],
                },
              ],
            },
            {
              topic: this.$t(
                "resources.intereactiveEductionCreativityMotivationTitle"
              ),
              description: this.$t(
                "resources.intereactiveEductionCreativityMotivationDescription"
              ),
              tools: [
                {
                  label: this.$t(
                    "resources.intereactiveEductionCreativityMotivationToolLabelOne"
                  ),
                  link: "https://www.inovujme.sk/sk/skoly",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "",
                  languages: ["sk"],
                },
              ],
            },
            {
              topic: this.$t(
                "resources.intereactiveEductionCreativityMotivationTitle"
              ),
              description: this.$t(
                "resources.intereactiveEductionCreativityMotivationDescription"
              ),
              tools: [
                {
                  label: this.$t(
                    "resources.intereactiveEductionCreativityMotivationToolLabelTwo"
                  ),
                  link: "https://www.youtube.com/watch?v=w24dBihQeEw",
                  colourID: 0,
                  type: "youtube_video",
                  thumbnail_url: "",
                  languages: ["sk"],
                },
              ],
            },
            {
              topic: this.$t(
                "resources.intereactiveEductionCreativityMotivationTitle"
              ),
              description: this.$t(
                "resources.intereactiveEductionCreativityMotivationDescription"
              ),
              tools: [
                {
                  label: this.$t(
                    "resources.intereactiveEductionCreativityMotivationToolLabelThree"
                  ),
                  link: "https://www.inovujme.sk/sk/clanok/zaver-workshopovej-cinnosti-narodneho-projektu-inovujme-sk-vo-velkom-style",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "",
                  languages: ["sk"],
                },
              ],
            },
          ],
        },
        {
          templateID: 4,
          title: this.$t("resources.podcast"),
          content: [
            {
              topic: "Tilskudd trøndelag fylkeskommune",
              description: "Tilskudd trøndelag",
              tools: [
                {
                  label: "Tilskudd trøndelag fylkeskommune",
                  link: "https://www.trondelagfylke.no/vare-tjenester/naring-og-innovasjon/virkemidler-og-tilskudd/",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "",
                  languages: ["nb"],
                },
              ],
            },
            {
              topic: "Innovasjon norge tilskudd",
              description: "",
              tools: [
                {
                  label: "Innovasjon norge tilskudd",
                  link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/981166cf-7c68-419f-9aae-9f421f418b82.pptx",
                  colourID: 0,
                  type: "files",
                  thumbnail_url: "",
                  languages: ["nb"],
                },
              ],
            },
            // {
            //   topic: "Instant Digital Economy? – Hungary Going Digital",
            //   description:
            //     "The analysis 'Instant Digital Economy? – Hungary Going Digital' from the Institute for Foreign Affairs and Trade explores the dynamics of digital transformation in Hungary, focusing on the optimism surrounding Industry 4.0's potential to boost productivity and economic growth, while also examining the challenges and policy recommendations essential for fostering sustainable digital advancement.",
            //   tools: [
            //     {
            //       label: "Instant Digital Economy? – Hungary Going Digital",
            //       link: "https://kki.hu/en/instant-digital-economy-hungary-going-digital/",
            //       colourID: 0,
            //       type: "external_content",
            //       thumbnail_url:
            //         "https://kki.hu/en/instant-digital-economy-hungary-going-digital/",
            //       languages: ["en"],
            //     },
            //   ],
            // },
            {
              topic:
                "Digitalisation and digital transformation in Hungary Implications for persons with disabilities",
              description:
                "The publication from the European Union's Official Publication Office offers a comprehensive look into Hungary's National Digitalization Strategy, focusing on enhancing digital infrastructure, skills, economy, and governance, with a keen eye on inclusivity and accessibility for persons with disabilities in the digital age.",
              tools: [
                {
                  label:
                    "Digitalisation and digital transformation in Hungary Implications for persons with disabilities",
                  link: "https://op.europa.eu/en/publication-detail/-/publication/052f7326-9df4-11ed-b508-01aa75ed71a1/language-en",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url:
                    "https://op.europa.eu/en/publication-detail/-/publication/052f7326-9df4-11ed-b508-01aa75ed71a1/language-en",
                  languages: ["en"],
                },
              ],
            },
            {
              topic:
                "Examining Digital Transformation Trends in Austrian and Hungarian Companies",
              description:
                "The study published in MDPI's Sustainability journal provides a comparative analysis of digital transformation efforts in Austria and Hungary. It delves into Industry 4.0 and 5.0's challenges and opportunities, highlighting the crucial role of knowledge acquisition, resource availability, and cybersecurity in navigating the digital era successfully.",
              tools: [
                {
                  label:
                    "Examining Digital Transformation Trends in Austrian and Hungarian Companies",
                  link: "https://www.mdpi.com/2071-1050/15/15/11891",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "https://www.mdpi.com/2071-1050/15/15/11891",
                  languages: ["en"],
                },
              ],
            },
            {
              topic: this.$t(
                "resources.supportDevelopmentInnovationEcosystemTitle"
              ),
              description: this.$t(
                "resources.supportDevelopmentInnovationEcosystemDescription"
              ),
              tools: [
                {
                  label: this.$t(
                    "resources.supportDevelopmentInnovationEcosystemToolLabelOne"
                  ),
                  link: "https://www.inovujme.sk/files/dokumenty-np-zivse/inova%C4%8Dn%C3%A9%20pouk%C3%A1%C5%BEky%20spr%C3%A1va.pdf",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "",
                  languages: ["sk"],
                },
              ],
            },
            {
              topic: this.$t(
                "resources.supportDevelopmentInnovationEcosystemTitle"
              ),
              description: this.$t(
                "resources.supportDevelopmentInnovationEcosystemDescription"
              ),
              tools: [
                {
                  label: this.$t(
                    "resources.supportDevelopmentInnovationEcosystemToolLabelTwo"
                  ),
                  link: "https://www.inovujme.sk/sk/clanok/siea-pokracuje-v-inovovani-slovenska-vycleni-16,5-miliona-eur-na-podporu-podnikov",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "",
                  languages: ["sk"],
                },
              ],
            },
            {
              topic: this.$t(
                "resources.supportDevelopmentInnovationEcosystemTitle"
              ),
              description: this.$t(
                "resources.supportDevelopmentInnovationEcosystemDescription"
              ),
              tools: [
                {
                  label: this.$t(
                    "resources.supportDevelopmentInnovationEcosystemToolLabelThree"
                  ),
                  link: "https://www.youtube.com/watch?v=NVN_eGwr8kw",
                  colourID: 0,
                  type: "youtube_video",
                  thumbnail_url: "",
                  languages: ["sk"],
                },
              ],
            },
            {
              topic: this.$t(
                "resources.financialContributionBusinessWorkplacesTitle"
              ),
              description: this.$t(
                "resources.financialContributionBusinessWorkplacesDescription"
              ),
              tools: [
                {
                  label: this.$t(
                    "resources.financialContributionBusinessWorkplacesToolLabelOne"
                  ),
                  link: "https://www.siea.sk/na-digitalne-a-inovacne-vouchery-pre-podniky-je-k-dispozicii-20-milionov-eur/",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "",
                  languages: ["sk"],
                },
              ],
            },
            {
              topic: this.$t("resources.supportDigitalFieldDitizationTitle"),
              description: this.$t(
                "resources.supportDigitalFieldDitizationDescription"
              ),
              tools: [
                {
                  label: this.$t(
                    "resources.supportDigitalFieldDitizationToolLabelOne"
                  ),
                  link: "https://www.siea.sk/edih-expandi-4-0-bezplatne-pomaha-slovenskym-podnikom-s-digitalizaciou/",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "",
                  languages: ["sk"],
                },
              ],
            },
            {
              topic: this.$t("resources.supportDigitalFieldDitizationTitle"),
              description: this.$t(
                "resources.supportDigitalFieldDitizationDescription"
              ),
              tools: [
                {
                  label: this.$t(
                    "resources.supportDigitalFieldDitizationToolLabelTwo"
                  ),
                  link: "https://www.expandi40.sk/sluzby/",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "",
                  languages: ["sk"],
                },
              ],
            },
            {
              topic: this.$t("resources.assistanceRecoveryResiliencePlanTitle"),
              description: this.$t(
                "resources.assistanceRecoveryResiliencePlanDescription"
              ),
              tools: [
                {
                  label: this.$t(
                    "resources.assistanceRecoveryResiliencePlanToolLabelOne"
                  ),
                  link: "https://edihcassovium.sk/wp-content/uploads/2023/12/Vyzva-pre-MSP.pdf",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "",
                  languages: ["sk"],
                },
              ],
            },
            {
              topic: this.$t("resources.assistanceNonFinancialSupportTitle"),
              description: this.$t(
                "resources.assistanceNonFinancialSupportDescription"
              ),
              tools: [
                {
                  label: this.$t(
                    "resources.assistanceNonFinancialSupportToolLabelOne"
                  ),
                  link: "https://edihcassovium.sk/wp-content/uploads/2023/12/Vyzva-pre-OVS.pdf",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "",
                  languages: ["sk"],
                },
              ],
            },
            {
              topic: this.$t("resources.assistanceNonFinancialSupportTitle"),
              description: this.$t(
                "resources.assistanceNonFinancialSupportDescription"
              ),
              tools: [
                {
                  label: this.$t(
                    "resources.assistanceNonFinancialSupportToolLabelTwo"
                  ),
                  link: "https://edihcassovium.sk/sluzby/",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "",
                  languages: ["sk"],
                },
              ],
            },
            {
              topic: this.$t("resources.provisionNonFinancialSupportTitle"),
              description: this.$t(
                "resources.provisionNonFinancialSupportDescription"
              ),
              tools: [
                {
                  label: this.$t(
                    "resources.provisionNonFinancialSupportToolLabelOne"
                  ),
                  link: "https://www.scdi.tech/vyzva-pre-podniky/",
                  colourID: 0,
                  type: "external_content",
                  thumbnail_url: "",
                  languages: ["sk"],
                },
              ],
            },
          ],
        },
      ],

      /* Data from the Follow-Up DB. - Need to be sorted by type
        - youtube_video = Link
        - external_content = Link
        - files = Link
      */
      dbResources: [],
    };
  },
  computed: {
    filteredResources() {
      const userSelectedLanguage = this.language;
      console.log("User selected language:", userSelectedLanguage);

      return this.manualResources
        .filter((resource) => {
          return resource.templateID === this.templateID;
        })
        .map((resource) => {
          const filteredContent = resource.content
            .map((content) => {
              const filteredTools = content.tools.filter((tool) =>
                tool.languages.includes(userSelectedLanguage)
              );
              return { ...content, tools: filteredTools };
            })
            .filter((content) => content.tools.length > 0);
          return { ...resource, content: filteredContent };
        })
        .filter((resource) => resource.content.length > 0);
    },
  },

  methods: {
    // Click on card will display corresponding template based upon templateID given in parameter | Closes the template if the same card is clicked again
    changeTemplate(templateID) {
      if (templateID === this.templateID) {
        this.templateID = 0;
      } else {
        this.templateID = templateID;
      }
    },
    // Query to get the data from the DB (if needed)
    getAllResources() {
      this.$http
        .get(
          `https://app.followup.prios.no/api/resource_management/content?tenant_id=108`,
          {
            headers: { Tempaccess: this.accessKey },
          }
        )
        .then((response) => {
          this.dbResources = response.data;
        });
    },
    // Map tool types to corresponding Vuetify icons
    getToolIcon(type) {
      const iconMap = {
        youtube_video: "mdi-youtube",
        external_content: "mdi-link",
        files: "mdi-file",
        // Add more types as needed
      };

      return iconMap[type] || "mdi-help-circle";
    },
    getToolColor(type) {
      const colorMap = {
        youtube_video: "red",
        external_content: "blue",
        // Add more types and colors as needed
      };
      return colorMap[type] || "black"; // Default to black if type not found
    },
    getButtonIcon(type) {
      const iconMap = {
        youtube_video: "mdi-open-in-new",
        external_content: "mdi-open-in-new",
        files: "mdi-open-in-new",
        // Add more types as needed
      };

      return iconMap[type] || "mdi-help-circle";
    },
    // Handle button click for the specific tool
    handleButtonClick(tool) {
      console.log("Button pressed for:", tool.type);
      // Open a new tab with the specified link
      if (tool.type === "external_content") {
        window.open(tool.link, "_blank");
      }
      if (tool.type === "youtube_video") {
        window.open(tool.link, "_blank");
      }
      if (tool.type === "files") {
        window.open(tool.link, "_blank");
      }
    },
    allowedCategoriesForTemplate(templateID) {
      if (templateID === 1) {
        return ["potato"];
      } else if (templateID === 2) {
        return [""];
      } else {
        return [];
      }
    },
    openMoreWindow(content, tool) {
      this.$refs.openingResourceDialog.openDialog(content, tool);
    },
  },
  mounted() {
    this.getAllResources();
  },
};
</script>

<style scoped>
.newsCardDividerPositioning {
  padding: 2px;
  position: absolute;
  bottom: 0;
}

.border-bottom {
  border-bottom: 1px solid #ccc;
}
</style>
