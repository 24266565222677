<template>
  <div>
    <!-- Floating card button at the bottom right -->
    <v-btn
      @click="showCard"
      class="custom-fab"
      :class="$vuetify.breakpoint.mdAndDown ? 'phoneMargin' : 'desktopMargin'"
      fab
      fixed
      top
      right
      style="background-color: #1565c0"
      v-if="resourceReview !== 'true' && showThisThing"
    >
      <p class="pt-3">Review our Resource Page</p>
    </v-btn>

    <!-- Floating card dialog -->
    <v-dialog v-model="cardDialog" max-width="800" bottom right>
      <v-card
        class="pa-5"
        style="background-color: #f6f8f9; border-radius: 10px"
      >
        <p class="headline mb-5" style="font-weight: bold">
          Please help us improve the Resource Page:
        </p>
        <v-container>
          <v-row v-for="(item, index) in userExperienceForm" :key="index">
            <v-col cols="12" class="pa-0">
              <p class="ma-0 pa-0">{{ item.label }}</p>
            </v-col>
            <v-col cols="12" class="ma-0 pa-0 mb-5">
              <v-row>
                <v-col
                  cols="5"
                  class="ma-0 pa-0 pt-2"
                  v-if="item.type == 'scale'"
                >
                  <v-rating
                    v-model="item.score"
                    background-color="grey darken-2"
                    color="orange"
                    :half-increments="false"
                    large
                  ></v-rating>
                </v-col>
                <v-col
                  cols="10"
                  class="ma-0 pa-0 pt-2 pl-3"
                  v-if="item.type == 'inputField'"
                >
                  <v-textarea
                    v-model="inputData"
                    color="orange"
                    background-color="grey lighten-2"
                    filled
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions class="ma-0 pa-0 mt-10">
          <v-btn
            @click="saveReview()"
            style="background-color: #234062; color: white"
            >Send Review</v-btn
          >
          <v-btn
            @click="hideCard"
            style="background-color: #595b5f; color: white"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cardDialog: false,
      accessKey: window.btoa("bac436b32a36431bb437b9509b6d3495"),
      dbForm: [],
      responseID: "",
      showThisThing: true,
      // Get the resourceReview from localstorage.  If it is true, then do not show the review card.
      resourceReview: localStorage.getItem("resourceReview"),
      inputData: "",
      formGuid: "92100d5b-d65d-4be6-8589-ce9bf5757969",

      userExperienceForm: [
        {
          label:
            "Do you agree with the following statement: In this Resource Page there are useful sources related to digital transition and digital skills learning? (1 = strongly disagree : 5 = totally agree)",
          score: 0,
          type: "scale",
          questionID: 4827,
        },
        {
          label:
            "How satisfied are you with the user experience of the Resource Page? (1 = very dissatisfied : 5 = very satisfied)",
          score: 0,
          type: "scale",
          questionID: 4828,
        },
        {
          label:
            "What additional types of content or resources would you like to see added to the platform? ",
          score: "",
          type: "inputField",
          questionID: 4829,
        },
      ],
    };
  },
  methods: {
    // Show the card dialog and reset the scores to 0
    showCard() {
      this.cardDialog = true;
      this.userExperienceForm.forEach((item) => {
        item.score = 0;
      });
      this.inputData = "";
      this.checkLocalStorage();
      this.getFormData();
    },
    // Hide the card dialog
    hideCard() {
      this.cardDialog = false;
    },

    checkLocalStorage() {
      if (localStorage.getItem("resourceReview") === null) {
        this.resourceReview = false;
      } else {
        this.resourceReview = localStorage.getItem("resourceReview");
      }
    },

    // Send to localstorage resourceReview to True, so that the review card is not shown again.  Create the key and set to true if it does not exist.
    sendToLocalStorage() {
      if (localStorage.getItem("resourceReview") === null) {
        localStorage.setItem("resourceReview", true);
      } else {
        localStorage.setItem("resourceReview", true);
      }
      this.showThisThing = false;
    },

    // Save the review
    saveReview() {
      let newResponse = [
        [
          this.responseID,
          this.userExperienceForm[0].questionID,
          this.userExperienceForm[0].score,
        ],
        [
          this.responseID,
          this.userExperienceForm[1].questionID,
          this.userExperienceForm[1].score,
        ],
        [
          this.responseID,
          this.userExperienceForm[2].questionID,
          this.inputData,
        ],
      ];
      console.log("Review data:", newResponse);

      this.$http
        .post(
          "https://app.followup.prios.no/api/form_builder/question_responses",
          newResponse,
          { headers: { Tempaccess: this.accessKey } }
        )
        .then((response) => {
          console.log("Responsen er på formdataen er", response.data);
          this.sendToLocalStorage();
          this.cardDialog = false;
          this.resourceReview = true;
          location.reload();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getFormData() {
      this.$http
        .get(
          `https://app.followup.prios.no/api/form_builder/responses?mode=getformresponseanddata&guid='92100d5b-d65d-4be6-8589-ce9bf5757969'`,
          { headers: { Tempaccess: this.accessKey } }
        )
        .then((response) => {
          this.dbForm = response.data;
          this.responseID = response.data.response_id;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  mounted() {},
};
</script>

<style scoped>
.custom-fab {
  height: 60px;
  border-radius: 8px;
  background-color: #2196f3; /* Change the background color as needed */
  color: #ffffff; /* Change the text color as needed */
}

.custom-fab:hover {
  background-color: #1565c0; /* Change the hover background color as needed */
}
.phoneMargin {
  margin-top: 90px;
  width: 93vw;
}
.desktopMargin {
  margin-top: 90px;
  width: 97vw;
}
</style>
