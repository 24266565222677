import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('NewsDialog',{ref:"openingNewsDialog"}),_c(VRow,[_c(VCol,{staticClass:"pt-15",attrs:{"cols":"12"}},[_c('p',{staticClass:"newsChapterTitle text-center"},[_vm._v(" "+_vm._s(_vm.$t("home.newsHeadliner"))+" ")]),_c(VDivider,{staticClass:"newsCardDividerPositioning",staticStyle:{"padding":"2px"},attrs:{"width":"98%"}})],1),_vm._l((_vm.allTheNews.slice(0, 3).reverse()),function(newsData,newsDataIndex){return _c(VCol,{key:newsDataIndex,attrs:{"cols":"12","xl":"4","lg":"4","md":"4","sm":"12","xs":"12"}},[_c(VCard,{staticClass:"pa-2",attrs:{"height":"100%"},on:{"click":function($event){return _vm.getNewsContent(newsData)}}},[_c(VRow,[_c(VCol,{staticClass:"pb-0",attrs:{"cols":"12"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[(newsData.media_url)?_c(VImg,{staticClass:"ml-2 mr-2",attrs:{"src":newsData.media_url,"alt":"News Image","width":"auto","height":"200px","cover":""}}):_c('div',{staticStyle:{"height":"200px","width":"auto","background-color":"#d1d1d1","margin":"0px 15px 0px 15px"}})],1),_c(VCol,{attrs:{"cols":"12"}},[(_vm.$vuetify.breakpoint.width <= 959)?_c('p',{staticClass:"newsCardTitle",staticStyle:{"font-size":"16px","height":"40px"}},[_vm._v(" "+_vm._s(newsData.title)+" ")]):(
                    _vm.$vuetify.breakpoint.width <= 1300 &&
                    _vm.$vuetify.breakpoint.width >= 960
                  )?_c('p',{staticClass:"newsCardTitle",staticStyle:{"font-size":"16px","height":"80px"}},[_vm._v(" "+_vm._s(newsData.title)+" ")]):_c('p',{staticClass:"newsCardTitle",staticStyle:{"font-size":"18px","height":"80px"}},[_vm._v(" "+_vm._s(newsData.title)+" ")]),_c('p',{staticClass:"newsCardDescription",staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(newsData.description)+" ")])]),_c(VCol,{staticClass:"pt-0 mt-0",attrs:{"cols":"12"}},[_c(VCardActions,{staticClass:"pt-0"},[_c('p',{staticClass:"newsCardButton mr-2 pt-3"},[_vm._v(" "+_vm._s(_vm.$t("home.readArticle"))+" ")]),_c(VIcon,{staticClass:"newsCardButtonArrow"},[_vm._v("mdi-arrow-right")])],1)],1)],1),_c(VDivider,{staticClass:"newsCardDividerPositioning",staticStyle:{"padding":"2px"},style:(`background-color:${_vm.colorArr[newsDataIndex]}`),attrs:{"width":"98%"}})],1),_c(VCol,{attrs:{"cols":"12"}})],1)],1)],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }