import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VAppBar,{attrs:{"app":"","color":"light-blue lighten-5"}},[_c('ContactForm',{ref:"OpenContactForm"}),_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c(VToolbarTitle,[_vm._v("Digi Ped z Gen")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.contactForm.openContact()}}},[_c(VIcon,[_vm._v("mdi-email-outline")])],1),_c(VNavigationDrawer,{attrs:{"absolute":"","bottom":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VRow,[_c(VCol,[_vm._v("asdsdadqwdqwd")])],1),_c(VList,{attrs:{"nav":"","dense":""}},[_c(VListItemGroup,{attrs:{"active-class":"deep-purple--text text--accent-4"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},[_c(VListItem,[_c(VListItemTitle,[_vm._v("Foo")])],1),_c(VListItem,[_c(VListItemTitle,[_vm._v("Bar")])],1),_c(VListItem,[_c(VListItemTitle,[_vm._v("Fizz")])],1),_c(VListItem,[_c(VListItemTitle,[_vm._v("Login")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }