import nb from "./lang.nb.json";
import en from "./lang.en.json";
import de from "./lang.de.json";
import sk from "./lang.sk.json";
import hu from "./lang.hu.json";

export default {
  en,
  nb,
  de,
  sk,
  hu,
};
