import { render, staticRenderFns } from "./rssForHome.vue?vue&type=template&id=f1fb8fdc&scoped=true&"
import script from "./rssForHome.vue?vue&type=script&lang=js&"
export * from "./rssForHome.vue?vue&type=script&lang=js&"
import style0 from "./rssForHome.vue?vue&type=style&index=0&id=f1fb8fdc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1fb8fdc",
  null
  
)

export default component.exports