import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"600"},model:{value:(_vm.resourceDialog),callback:function ($$v) {_vm.resourceDialog=$$v},expression:"resourceDialog"}},[_c(VCard,{staticClass:"pa-5"},[_c(VRow,{staticClass:"ma-0"},[_c(VCol,{attrs:{"cols":"10"}},[_c('p',{staticClass:"title text"},[_vm._v(_vm._s(_vm.resourceContent.topic))])]),_c(VCol,{staticClass:"d-flex justify-end ma-0 pa-0",attrs:{"cols":"1"}},[_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-close")])],1)],1)],1),(_vm.resourceTool.thumbnail_url)?_c(VImg,{staticClass:"ml-1 mb-5",attrs:{"src":_vm.resourceTool.thumbnail_url,"alt":"Resource Tool Thumbnail","max-height":"200px","contain":""}}):_vm._e(),_c(VCardText,[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.resourceContent.description))]),(_vm.resourceTool.extraLink)?_c('a',{attrs:{"href":_vm.resourceTool.extraLink,"target":"_blank"}},[_c('p',[_vm._v(_vm._s(_vm.resourceTool.extraLink))])]):_vm._e(),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.resourceTool.label))]),_c('a',{attrs:{"href":_vm.resourceTool.link,"target":"_blank"}},[_c('p',[_vm._v(_vm._s(_vm.resourceTool.link))])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }