<template>
  <v-app>
    <VisitorTracker></VisitorTracker>
    <Sidebar v-if="$vuetify.breakpoint.mdAndDown"></Sidebar>
    <Navbar v-else></Navbar>

    <!-- <NavbarPhone></NavbarPhone> -->
    <v-main>
      <router-view />
    </v-main>
    <PageReview> </PageReview>
    <Footer></Footer>
  </v-app>
</template>

<script>
import Navbar from "@/components/Global/Navbar.vue";
import NavbarPhone from "@/components/Global/NavbarPhone.vue";
import LanguageTest from "@/components/TestAndDevelopment/LanguageTest.vue";
import SelectLocale from "@/components/TestAndDevelopment/SelectLocale.vue";
import Sidebar from "@/components/Global/Sidebar.vue";
import Footer from "@/components/Global/Footer.vue";
import VisitorTracker from "@/components/VisitTracker/VisitorTracker.vue";
import PageReview from "@/components/Global/PageReview.vue";

export default {
  name: "App",
  components: {
    Navbar,
    Sidebar,
    NavbarPhone,
    LanguageTest,
    SelectLocale,
    Footer,
    VisitorTracker,
    PageReview,
  },
  data() {
    return {};
  },
  methods: {
    // Checks if finding language key, if not setting it to default en
    checkLanguage() {
      if (!localStorage.getItem("language")) {
        localStorage.setItem("language", "en");
      }
    },
  },
  created() {
    this.checkLanguage();
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@700&family=Lato:wght@400;700&display=swap");
</style>
