<template>
  <v-dialog v-model="invitationEventDialog" max-width="600">
    <v-card class="pa-5">
      <!-- Title and Close Button Row -->
      <v-row class="ma-0">
        <!-- Title Column -->
        <v-col cols="10">
          <p class="title text">Inprofuture Event</p>
        </v-col>

        <!-- Close Button Column -->
        <v-col cols="1" class="d-flex justify-end ma-0 pa-0">
          <v-btn icon @click="closeDialog">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <p>
            Everyone is welcome to join us on 7 March at 9:00 am at Opera Café
            in Budapest, where we will present the main results and experiences
            of the INPROFUTURE Project.
          </p>
          <p>
            Please send an e-mail to
            <a :href="'mailto:' + email">{{ email }}</a> by 5 March to register
            your interest to participate
          </p>
          <!-- Divider -->
          <v-divider class="my-4"></v-divider>
          <v-img
            src="@/assets/images/eventinvitation.png"
            alt="Inprofuture Event Invitation"
            max-height="auto"
            contain
            class="ml-1 mb-5"
          ></v-img>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      invitationEventDialog: false,
      email: "progress@progress.hu",
    };
  },
  methods: {
    // Open the Dialog
    openDialog() {
      this.invitationEventDialog = true;
    },

    // Close the dialog
    closeDialog() {
      this.invitationEventDialog = false;
    },

    // Clean the dialog
    //   resetDialog() {
    //     this.resourceContent = [];
    //     this.resourceTool = [];
    //   },
  },
};
</script>

<style scoped>
.break-all {
  word-break: break-all;
}
</style>
