<template>
  <v-dialog v-model="resourceDialog" max-width="600">
    <v-card class="pa-5">
      <!-- Title and Close Button Row -->
      <v-row class="ma-0">
        <!-- Title Column -->
        <v-col cols="10">
          <p class="title text">{{ resourceContent.topic }}</p>
        </v-col>

        <!-- Close Button Column -->
        <v-col cols="1" class="d-flex justify-end ma-0 pa-0">
          <v-btn icon @click="closeDialog">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <!-- Image and Content -->
      <v-img
        v-if="resourceTool.thumbnail_url"
        :src="resourceTool.thumbnail_url"
        alt="Resource Tool Thumbnail"
        max-height="200px"
        contain
        class="ml-1 mb-5"
      ></v-img>
      <v-card-text>
        <p class="mb-0">{{ resourceContent.description }}</p>
        <a
          v-if="resourceTool.extraLink"
          :href="resourceTool.extraLink"
          target="_blank"
        >
          <p>{{ resourceTool.extraLink }}</p>
        </a>

        <!-- extraLink -->
        <!-- <p class="title mb-0">Tool</p> -->
        <p class="mb-0">{{ resourceTool.label }}</p>
        <a :href="resourceTool.link" target="_blank">
          <p>{{ resourceTool.link }}</p>
        </a>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      resourceDialog: false,
      resourceContent: [],
      resourceTool: [],
    };
  },
  methods: {
    // Open the Dialog
    openDialog(content, tool) {
      this.resourceDialog = true;
      this.resetDialog();
      this.resourceContent = content;
      this.resourceTool = tool;
    },

    // Close the dialog
    closeDialog() {
      this.resourceDialog = false;
      this.resetDialog();
    },

    // Clean the dialog
    resetDialog() {
      this.resourceContent = [];
      this.resourceTool = [];
    },
  },
};
</script>

<style scoped>
.break-all {
  word-break: break-all;
}
</style>
