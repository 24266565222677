<template>
  <v-footer color="#FFFFFF" elevation="10">
    <v-row no-gutters>
      <v-col cols="12">
        <v-divider class="dividerStyling"></v-divider>
      </v-col>

      <!-- EU Logo - Phone -->
      <v-col
        cols="12"
        xl="2"
        lg="2"
        md="2"
        sm="12"
        xs="12"
        v-if="$vuetify.breakpoint.mdAndDown"
        class="mb-5"
      >
        <v-img
          v-if="languageSelected == 'en'"
          src="img/CoFundedEnglish.png"
          max-width="300"
        ></v-img>
        <v-img
          v-if="languageSelected == 'nb'"
          src="img/CoFundedNorwegian.png"
          max-width="300"
        ></v-img>
        <v-img
          v-if="languageSelected == 'sk'"
          src="img/CoFundedSlovakian.png"
          max-width="300"
        ></v-img>
        <v-img
          v-if="languageSelected == 'hu'"
          src="img/CoFundedHungary.png"
          contain
          max-width="300"
        ></v-img>
      </v-col>

      <!-- EU Logo - Desktop -->
      <v-col v-else cols="12" xl="1" lg="1" md="1" sm="12" xs="12">
        <v-img
          v-if="languageSelected == 'en'"
          src="img/CoFundedEnglish.png"
          contain
          height="70"
        ></v-img>
        <v-img
          v-if="languageSelected == 'nb'"
          src="img/CoFundedNorwegian.png"
          contain
          height="70"
        ></v-img>
        <v-img
          v-if="languageSelected == 'sk'"
          src="img/CoFundedSlovakian.png"
          contain
          height="70"
        ></v-img>
        <v-img
          v-if="languageSelected == 'hu'"
          src="img/CoFundedHungary.png"
          contain
          height="70"
          class="mt-5"
        ></v-img>
      </v-col>

      <!-- Contact Info Text - Phone -->
      <v-col
        cols="12"
        xl="8"
        lg="8"
        md="2"
        sm="12"
        xs="12"
        class="pl-0 mr-10"
        v-if="$vuetify.breakpoint.mdAndDown"
      >
        <p class="footerTextPhone ma-0 pa-0">
          {{ $t("footer.progressConsult") }}
        </p>
        <p class="footerTextPhone ma-0 pa-0">
          {{ $t("footer.footerAdressOne") }}
        </p>
        <p class="footerTextPhone ma-0 pa-0">
          {{ $t("footer.footerAdressTwo") }}
        </p>
        <p class="footerTextPhone ma-0 pa-0">
          <a href="progress@progress.hu" target="_blank"
            >progress@progress.hu</a
          >
        </p>
      </v-col>

      <!-- Contact Info and Disclaimer text - Desktop 3 2 3 -->
      <v-col
        cols="12"
        xl="12"
        lg="10"
        md="10"
        sm="12"
        xs="12"
        class="pl-15 pt-3"
        v-else
      >
        <div style="display: flex">
          <div>
            <p class="footerText ma-0 pa-0 ml-5">
              {{ $t("footer.progressConsult") }}
            </p>
            <p class="footerText ma-0 pa-0 ml-5">
              {{ $t("footer.footerAdressOne") }}
            </p>
            <p class="footerText ma-0 pa-0 ml-5">
              {{ $t("footer.footerAdressTwo") }}
            </p>
            <p class="footerText ma-0 pa-0 ml-5">
              <a href="progress@progress.hu" target="_blank"
                >progress@progress.hu</a
              >
            </p>
          </div>
          <div>
            <p class="footerTextPhone mt-3 ml-10">
              {{ $t("footer.euFooterText") }}
            </p>
          </div>
        </div>
      </v-col>

      <!-- Disclaimer Text - Phone -->
      <v-col
        v-if="$vuetify.breakpoint.mdAndDown"
        cols="12"
        xl="2"
        lg="2"
        md="6"
        sm="12"
        xs="12"
        class="pt-3"
        style="font-size: 12px"
      >
        {{ $t("footer.euFooterText") }}
      </v-col>

      <!-- Logo of Page -->
      <v-col cols="12" xl="1" lg="1" md="1" sm="12" xs="12">
        <v-img
          v-if="$vuetify.breakpoint.mdAndDown"
          class="mt-5 mb-5"
          src="img/newLogo.jpg"
          max-height="150"
          max-width="150"
          contain
        ></v-img>
        <v-img
          v-else
          class="mt-5 ml-10"
          src="img/newLogo.jpg"
          max-height="130"
          max-width="130"
          contain
        ></v-img>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      languageSelected: this.$i18n.locale,
    };
  },
};
</script>

<style scoped>
.cursorStyle {
  cursor: pointer;
}
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.borderTop {
  border-top: red solid 2px;
  box-shadow: 0px 2px 12px #00000033;
}

.dividerStyling {
  background-color: #47b5b0;
  border-radius: 4px;
  opacity: 1;
  padding: 2px;
  margin-bottom: 20px;
  margin-top: 5px;
}

.footerText {
  font-family: "Lato", sans-serif;
  text-align: left;
  letter-spacing: 0px;
  color: #141428;
  opacity: 1;
  font-size: 14px;
}

.footerTextPhone {
  font-family: "Lato", sans-serif;
  text-align: left;
  letter-spacing: 0px;
  color: #141428;
  opacity: 1;
  font-size: 12px;
}
</style>
