import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","fullscreen":""},model:{value:(_vm.imageDialog),callback:function ($$v) {_vm.imageDialog=$$v},expression:"imageDialog"}},[_c(VCard,[_c(VAppBar,{staticStyle:{"top":"0","position":"sticky","z-index":"2"},attrs:{"clipped-left":"","color":"primaryColor","extension-height":8}},[_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VRow,[_c(VCol,{staticClass:"pt-10",attrs:{"cols":"12"}},[_c(VImg,{attrs:{"contain":"","max-width":"100vw","max-height":"100vh","src":_vm.imageToDisplay},on:{"click":_vm.closeDialog}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }