<template>
  <div>
    <v-row class="mb-1">
      <v-col cols="12" class="mt-15"></v-col>
      <v-col cols="12" class="ma-0 pa-0 mb-15">
        <p class="handbookTitle">{{ $t("navbar.handbook") }}</p>
      </v-col>
      <!-- <pre>{{ selectedHandbooks }}</pre> -->
      <v-col
        cols="12"
        xl="4"
        lg="4"
        md="4"
        sm="12"
        xs="12"
        class="ma-0 pa-0 pa-5"
        v-for="(handbook, handbookIndex) in selectedHandbooks"
        :key="handbookIndex"
        @click="registerClickedElement(handbook)"
      >
        <a :href="handbook.handbookLink" target="_blank">
          <v-hover v-slot:default="{ hover }">
            <v-card :elevation="hover ? 12 : 2">
              <v-img
                :src="handbook.handbookImage"
                width="300px"
                height="auto"
                contain
                class="mx-auto"
              ></v-img>
            </v-card>
          </v-hover>
        </a>
      </v-col>
    </v-row>
    <v-row class="mb-10">
      <v-col
        v-for="(handbook, handbookIndex) in selectedHandbooks"
        :key="handbookIndex"
        @click="registerClickedElement(handbook)"
      >
        <a
          :href="handbook.downloadLink"
          download
          class="btn btn-primary pr-10 pl-10 border-none text-[#010200] normal-case font-bold rounded-full"
        >
          <v-btn>{{ $t("contact.downloadButton") }}</v-btn>
        </a>
      </v-col>
    </v-row>
    <!-- -->
    <!-- <v-btn @click="downloadPDF()">asdqwdqd</v-btn> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      languages: ["nb", "en", "hu", "sl"],
      selectedLanguage: "en",
      accessKey: process.env.VUE_APP_API_KEY,
      handbooks: {
        nb: [
          {
            handbookTitle: "Handbook 1 - nb",
            handbookImage: "img/handbookNorwegian.png",
            handbookLink: "https://anyflip.com/whhx/jhtk/",
            languageSelected: "nb",
            downloadLink: ".../../pdfFiles/norwegian.pdf",
          },
        ],
        en: [
          {
            handbookTitle: "Handbook 1 - en",
            handbookImage: "img/handbook1.jpg",
            handbookLink: "https://anyflip.com/whhx/jkrw/",
            languageSelected: "en",
            downloadLink: ".../../pdfFiles/englishv2.pdf",
          },
        ],
        hu: [
          {
            handbookTitle: "Handbook 1 - hu",
            handbookImage: "img/huuu.png",
            handbookLink: "https://anyflip.com/whhx/xccc/",
            languageSelected: "hu",
            downloadLink: ".../../pdfFiles/hungarian.pdf",
          },
        ],
        sk: [
          {
            handbookTitle: "Handbook 1 - sk",
            handbookImage: "img/slovvk.png",
            handbookLink: "https://anyflip.com/whhx/dwhz/",
            languageSelected: "sk",
            downloadLink: ".../../pdfFiles/slovak.pdf",
          },
        ],
      },
    };
  },
  methods: {
    registerClickedElement(elementClicked) {
      // Set the Date
      const date = new Date();
      const formattedMonth = (date.getMonth() + 1).toString().padStart(2, "0");
      const dateFormatted = `${date.getFullYear()}-${formattedMonth}-${date.getDate()}T${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
      // Data to send
      let dataToSend = {
        tenant_id: 108,
        media_id: null,
        language: elementClicked.languageSelected,
        date: dateFormatted,
        type: "Click",
        name: elementClicked.handbookTitle,
      };
      // Send the data
      this.$http
        .post(
          "https://app.followup.prios.no/api/resource_management/tracking",
          dataToSend,
          { headers: { Tempaccess: this.accessKey } }
        )
        .then(() => {});
    },

    // Download pdf from asset folder
    // downloadPDF() {
    //   const link = document.createElement("a");
    //   link.href = require("@/assets/pdfs/slovak.pdf");
    //   link.download = "english.pdf";
    //   link.click();
    // },
  },
  computed: {
    selectedHandbooks() {
      return this.handbooks[this.selectedLanguage] || [];
    },
  },
  mounted() {
    this.selectedLanguage = localStorage.getItem("language");
  },
};
</script>

<style scoped>
/* General Page */
.homePageWidth {
  width: 90vw;
  margin: auto auto;
}

.handbookTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 46px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.handbookDescription {
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 14px;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
</style>
