import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,{staticClass:"mb-1"},[_c(VCol,{staticClass:"mt-15",attrs:{"cols":"12"}}),_c(VCol,{staticClass:"ma-0 pa-0 mb-15",attrs:{"cols":"12"}},[_c('p',{staticClass:"handbookTitle"},[_vm._v(_vm._s(_vm.$t("navbar.handbook")))])]),_vm._l((_vm.selectedHandbooks),function(handbook,handbookIndex){return _c(VCol,{key:handbookIndex,staticClass:"ma-0 pa-0 pa-5",attrs:{"cols":"12","xl":"4","lg":"4","md":"4","sm":"12","xs":"12"},on:{"click":function($event){return _vm.registerClickedElement(handbook)}}},[_c('a',{attrs:{"href":handbook.handbookLink,"target":"_blank"}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c(VCard,{attrs:{"elevation":hover ? 12 : 2}},[_c(VImg,{staticClass:"mx-auto",attrs:{"src":handbook.handbookImage,"width":"300px","height":"auto","contain":""}})],1)]}}],null,true)})],1)])})],2),_c(VRow,{staticClass:"mb-10"},_vm._l((_vm.selectedHandbooks),function(handbook,handbookIndex){return _c(VCol,{key:handbookIndex,on:{"click":function($event){return _vm.registerClickedElement(handbook)}}},[_c('a',{staticClass:"btn btn-primary pr-10 pl-10 border-none text-[#010200] normal-case font-bold rounded-full",attrs:{"href":handbook.downloadLink,"download":""}},[_c(VBtn,[_vm._v(_vm._s(_vm.$t("contact.downloadButton")))])],1)])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }