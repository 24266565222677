import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRating } from 'vuetify/lib/components/VRating';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.pageReview !== 'true' && _vm.showThisThing)?_c(VBtn,{staticClass:"custom-fab",staticStyle:{"background-color":"#1565c0"},attrs:{"fab":"","fixed":"","bottom":"","right":""},on:{"click":_vm.showCard}},[_c('p',{staticClass:"pt-3",staticStyle:{"font-size":"12px"}},[_vm._v("User experience of the webpage")])]):_vm._e(),_c(VDialog,{attrs:{"max-width":"800","bottom":"","right":""},model:{value:(_vm.cardDialog),callback:function ($$v) {_vm.cardDialog=$$v},expression:"cardDialog"}},[_c(VCard,{staticClass:"pa-5",staticStyle:{"background-color":"#f6f8f9","border-radius":"10px"}},[_c('p',{staticClass:"headline mb-5",staticStyle:{"font-weight":"bold"}},[_vm._v(" Please help us improve this webpage by submitting your rating: ")]),_c(VContainer,_vm._l((_vm.userExperienceForm),function(item,index){return _c(VRow,{key:index},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"ma-0 pa-0"},[_vm._v(_vm._s(item.label))])]),_c(VCol,{staticClass:"ma-0 pa-0 mb-5",attrs:{"cols":"12"}},[_c(VRow,[_c(VCol,{staticClass:"ma-0 pa-0 pt-2",attrs:{"cols":"5"}},[_c(VRating,{attrs:{"background-color":"grey darken-2","color":"orange","half-increments":false,"large":""},model:{value:(item.score),callback:function ($$v) {_vm.$set(item, "score", $$v)},expression:"item.score"}})],1)],1)],1)],1)}),1),_c(VCardActions,{staticClass:"ma-0 pa-0 mt-10"},[_c(VBtn,{staticStyle:{"background-color":"#234062","color":"white"},on:{"click":function($event){return _vm.saveReview()}}},[_vm._v(" Send Review ")]),_c(VBtn,{staticStyle:{"background-color":"#595b5f","color":"white"},on:{"click":_vm.hideCard}},[_vm._v(" Close ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }