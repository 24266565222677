import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ResourceDialog',{ref:"openingResourceDialog"}),_c('ResourceReview'),_c(VRow,{staticClass:"justify-center"},[_c(VCol,{staticClass:"mt-10",attrs:{"cols":"12"}}),_c(VCol,{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-center title mt-10"},[_vm._v(_vm._s(_vm.$t("home.resources")))])])],1),_c(VRow,{staticClass:"justify-center"},[_c(VCol,{staticClass:"mt-10 mb-10",attrs:{"cols":"8"}},[_c(VRow,_vm._l((_vm.resourceCards),function(resourceCard){return _c(VCol,{key:resourceCard.templateID,attrs:{"cols":"12","xl":"4","lg":"6","md":"6","sm":"12","xs":"12"}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c(VCard,{attrs:{"elevation":hover ? 12 : 2},on:{"click":function($event){return _vm.changeTemplate(resourceCard.templateID)}}},[_c('p',{staticClass:"text-center title mb-0 py-10"},[_vm._v(" "+_vm._s(resourceCard.label)+" ")]),_c(VDivider,{staticClass:"newsCardDividerPositioning",style:(`background-color:${
                  _vm.colorArr[resourceCard.templateID]
                }`),attrs:{"width":"100%"}})],1)]}}],null,true)})],1)}),1)],1)],1),(_vm.templateID !== 0)?_c(VRow,{staticClass:"mb-10"},_vm._l((_vm.filteredResources),function(resource){return _c(VCol,{key:resource.templateID,attrs:{"cols":"12"}},[_c(VDivider,{staticClass:"my-10"}),_c('p',{staticClass:"text-center title mb-10"},[_vm._v(_vm._s(resource.title))]),_c(VRow,_vm._l((resource.content),function(content){return _c(VCol,{key:content.topic,staticClass:"mx-auto",attrs:{"cols":"10"}},[_c(VRow,[(resource.templateID == 1)?_c(VCol,{staticClass:"pb-0 pt-10",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-center title"},[_vm._v(_vm._s(content.topic))])]):_vm._e(),_vm._l((content.tools),function(tool){return _c(VCol,{key:tool.label,attrs:{"cols":"12"}},[(tool.languages.includes(_vm.language))?_c(VRow,{staticClass:"border-bottom"},[_c(VCol,{attrs:{"cols":"2","xl":"1","lg":"1","md":"1","sm":"2","xs":"2"}},[_c(VIcon,{attrs:{"large":"","color":_vm.getToolColor(tool.type)}},[_vm._v(_vm._s(_vm.getToolIcon(tool.type)))])],1),_c(VCol,{staticClass:"mt-1 pl-0",attrs:{"cols":"8","xl":"8","lg":"8","md":"8","sm":"8","xs":"8"}},[_vm._v(" "+_vm._s(tool.label)+" ")]),_c(VCol,{class:{
                    'd-flex justify-end align-center':
                      _vm.$vuetify.breakpoint.mdAndUp,
                    'text-right': _vm.$vuetify.breakpoint.mdAndUp ? true : false,
                  },attrs:{"cols":"2","xl":"3","lg":"3","md":"3","sm":"2","xs":"2"}},[_c(VBtn,{staticClass:"mr-5",attrs:{"icon":""},on:{"click":function($event){return _vm.handleButtonClick(tool)}}},[_c(VIcon,{attrs:{"color":"blue"}},[_vm._v(_vm._s(_vm.getButtonIcon(tool.type)))])],1),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.openMoreWindow(content, tool)}}},[_c(VIcon,{attrs:{"color":"green"}},[_vm._v("mdi-information-outline")])],1)],1)],1):_vm._e()],1)})],2)],1)}),1)],1)}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }