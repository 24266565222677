import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDialog,{attrs:{"fullscreen":""},model:{value:(_vm.newsDialog),callback:function ($$v) {_vm.newsDialog=$$v},expression:"newsDialog"}},[_c(VCard,[_c(VCardTitle,[_c(VSpacer),(_vm.$vuetify.breakpoint.mdAndDown)?_c('span',{staticClass:"newsDialogTitlePhone"},[_vm._v(_vm._s(_vm.newsCardData.title))]):_c('span',{staticClass:"newsDialogTitle"},[_vm._v(_vm._s(_vm.newsCardData.title))]),_c(VSpacer),_c(VBtn,{staticClass:"closeButtonStyling",attrs:{"large":"","color":"error","icon":""},on:{"click":_vm.closeNewsDialog}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"pb-10",staticStyle:{"min-height":"180px"}},[(_vm.newsCardData.media_url)?_c(VImg,{attrs:{"src":_vm.newsCardData.media_url,"alt":"News Image","max-height":"300px","contain":""}}):_c('div',{staticStyle:{"height":"200px","width":"auto","background-color":"#D1D1D1","margin":"0px 15px 0px 15px"}})],1),_c(VDivider),_c(VDivider),_c(VDivider),_c(VRow,{staticClass:"ma-0 pa-0"},_vm._l((_vm.newsCardContentData),function(item,index){return _c(VCol,{key:index,staticClass:"ma-0 pa-0 pa-5",attrs:{"cols":"12","sm":"12","xs":"12","xl":item.class_list.split('xs')[1],"lg":item.class_list.split('xs')[1],"md":item.class_list.split('xs')[1]}},[(item.type == 'image')?_c('div',[(item.content)?_c(VImg,{attrs:{"src":item.content,"alt":"News Image","max-height":"600","contain":""}}):_vm._e()],1):(item.type == 'text')?_c('div',[_c('p',{staticClass:"pl-5 pb-5 mb-0 newsDialogDescription",domProps:{"innerHTML":_vm._s(item.content)}})]):_c('div',[_c('p',[_vm._v(_vm._s(item))])])])}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }