<template>
  <div>
    <ContactUs ref="OpenContactForm"></ContactUs>
    <!-- Navbar -->
    <v-app-bar app color="light-blue lighten-5" style="height: 6em">
      <a href="/">
        <v-img
          v-if="$vuetify.breakpoint.mdAndDown"
          class="mt-7"
          src="img/newLogo.jpg"
          max-height="150"
          max-width="150"
          contain
        ></v-img>
        <v-img
          v-else
          class="mt-7"
          src="img/newLogo.jpg"
          max-height="130"
          max-width="130"
          contain
        ></v-img>
      </a>
      <v-spacer></v-spacer>
      <div class="ml-5 mt-10">
        <LanguageSelection></LanguageSelection>
      </div>
      <v-btn icon @click="toggleNavbar()" class="mt-10">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="$refs.OpenContactForm.openContactDialog()"
        class="mt-10"
      >
        <v-icon>mdi-email-outline</v-icon>
      </v-btn>
    </v-app-bar>
    <!-- Navbar -->
    <v-navigation-drawer v-model="navDrawer" app>
      <a href="/">
        <v-img
          v-if="$vuetify.breakpoint.mdAndDown"
          class="mt-5"
          src="img/newLogo.jpg"
          max-height="150"
          max-width="150"
          contain
        ></v-img>
        <v-img
          v-else
          class="mt-5"
          src="img/newLogo.jpg"
          max-height="130"
          max-width="130"
          contain
        ></v-img>
      </a>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-item
          v-for="(item, index) in pageNavigation"
          :key="index"
          :to="item.location"
          @click.prevent="registerClickedElement(item)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="$refs.OpenContactForm.openContactDialog()">
          <v-list-item-icon>
            <v-icon>mdi-email-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("navbar.contact") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <!-- <v-btn text href="https://app.followup.prios.no/#/login" target="_blank">Login</v-btn> -->
    </v-navigation-drawer>
  </div>
</template>

<script>
import ContactUs from "@/components/Global/ContactUs.vue";
import LanguageSelection from "@/components/TestAndDevelopment/SelectLocale.vue";
export default {
  components: {
    ContactUs,
    LanguageSelection,
  },
  data() {
    return {
      navDrawer: false,
      expand: true,
      selectedLanguage: localStorage.getItem("language"),
      accessKey: process.env.VUE_APP_API_KEY,
      pageNavigation: [
        {
          name: this.$t("navbar.home"),
          location: "/",
          icon: "",
          nameTwo: "Home",
        },
        {
          name: this.$t("navbar.about"),
          location: "/about",
          icon: "",
          nameTwo: "About",
        },
        {
          name: this.$t("navbar.partners"),
          location: "/partners",
          icon: "",
          nameTwo: "Partners",
        },
        {
          name: this.$t("navbar.news"),
          location: "/news",
          icon: "",
          nameTwo: "News",
        },
        // { name: "RSS", location: "/rss", icon: "" },
        {
          name: this.$t("navbar.mappingTool"),
          location: "/mappingtool",
          icon: "",
          nameTwo: "MappingTool",
        },
        {
          name: this.$t("navbar.handbook"),
          location: "/handbook",
          icon: "",
          nameTwo: "Handbook",
        },
        {
          name: this.$t("navbar.resources"),
          location: "/resources",
          icon: "",
          nameTwo: "Resources",
        },
      ],
    };
  },

  methods: {
    // Toggles the sidebar on and offs
    toggleNavbar() {
      if (this.navDrawer == true) {
        this.navDrawer = false;
      } else {
        this.navDrawer = true;
      }
    },
    // Register click on element
    registerClickedElement(elementClicked) {
      // Set the Date
      const date = new Date();
      const formattedMonth = (date.getMonth() + 1).toString().padStart(2, "0");
      const dateFormatted = `${date.getFullYear()}-${formattedMonth}-${date.getDate()}T${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

      // format langauge from sl to sk
      let clickedLanguage = "";

      if (this.selectedLanguage === "sl") {
        clickedLanguage = "sk";
      } else {
        clickedLanguage = this.selectedLanguage;
      }
      // Data to send
      let dataToSend = {
        tenant_id: 108,
        media_id: null,
        language: clickedLanguage,
        date: dateFormatted,
        type: "SidebarClick - phone",
        name: elementClicked.nameTwo,
      };

      // Only allow sending data on nameTwo == mappingTool, handbook and resources
      if (
        elementClicked.nameTwo === "MappingTool" ||
        elementClicked.nameTwo === "Handbook" ||
        elementClicked.nameTwo === "Resources"
      ) {
        this.$http
          .post(
            "https://app.followup.prios.no/api/resource_management/tracking",
            dataToSend,
            { headers: { Tempaccess: this.accessKey } }
          )
          .then(() => {});
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");

.fontTitle {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
}
.fontSubTitle {
  font-family: "Poppins", sans-serif;
  font-size: 19px;
}
.fontContent {
  font-size: 19px;
  font-family: "Source Sans Pro", sans-serif;
}
p {
  font-family: "Source Sans Pro", sans-serif;
}
</style>
