<template>
  <div>
    <h1>Register Click Example</h1>
    <v-btn :disabled="!isSubmitting" @click="createDataForSubmit()">
      Register Click
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "RegisterClickExample",
  data() {
    return {
      // Gets the access key from the .env file
      accessKey: process.env.VUE_APP_API_KEY,

      // Prevent user from spamming the button
      isSubmitting: true,

      // Sets the date format - DD-MM-YYYY HH:MM:SS
      dateFormat: "",
    };
  },

  methods: {
    // Get current date in DD-MM-YYYY HH:MM:SS format
    getCurrentDate() {
      const date = new Date();
      const formattedMonth = (date.getMonth() + 1).toString().padStart(2, "0");
      const dateFormated = `${date.getFullYear()}-${formattedMonth}-${date.getDate()}T${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
      this.dateFormat = dateFormated;
    },

    // Creates the data to submit
    createDataForSubmit() {
      let dataToSubmit = {
        tenant_id: 108,
        media_id: null,
        language: localStorage.getItem("language") || "en",
        date: this.dateFormat,
        type: "click",
        name: "Handbook version two",
      };
      this.registerClick(dataToSubmit);
    },

    // Disable the button temporarily after click
    disableButton() {
      if (this.isSubmitting) {
        this.isSubmitting = false;
        setTimeout(() => {
          this.isSubmitting = true;
        }, 500);
      }
    },

    // Register the click to DB
    registerClick(dataToSubmit) {
      this.disableButton();
      this.$http
        .post(
          "https://app.followup.prios.no/api/resource_management/tracking",
          dataToSubmit,
          { headers: { Tempaccess: this.accessKey } }
        )
        .then(() => {});
    },
  },

  mounted() {
    this.getCurrentDate();
  },
};
</script>
